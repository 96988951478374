import { getDomain } from 'config/config';
import processCollaborator from './Collaborators/process';

export function transformSpace(space) {
  if (space.IsDefault && space.AvatarUrl.includes('hashtag2.svg')) {
    space.AvatarUrl = `${getDomain()}/icons/skyblue/Technology/menu%20dots.svg`;
  }

  space.NameLowerCase = space.Name ? space.Name.toLowerCase() : '';

  space.IsChat = space.Type === 'CHAT' || space.WorkspaceId === null;
  space.IsConversation = space.WorkspaceId === null;

  space.Collaborators = space.Collaborators.map(collaborator =>
    processCollaborator(collaborator, space.Id)
  );

  space.Owner = processCollaborator(space.Owner, space.Id);
  space.OwnerEmail = space.Owner.Email;
  space.OwnerName = space.Owner.Name;

  if (!space.LastUpdateTimestamp) {
    space.LastUpdateTimestamp = space.Created;
  }

  return space;
}

export function transformSpaces(items) {
  if (!items) {
    return [];
  }
  return items
    .map(item => transformSpace(item))
    .filter(space => !space.Archived);
}
