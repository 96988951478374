import React from 'react';
import PropTypes from 'prop-types';
import NoteAvatar from 'Components/Notes/NoteAvatar';

function NoteOwnerAvatar({ note }) {
  return <NoteAvatar channelId={note.channelId} userId={note.ownerId} />;
}

NoteOwnerAvatar.propTypes = {
  note: PropTypes.object.isRequired,
};

export default NoteOwnerAvatar;
