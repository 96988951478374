import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';

function NoteDate({ date }) {
  return (
    <span>
      <FormattedDate value={date} year="numeric" month="short" day="2-digit" />
      &nbsp;
      <FormattedTime
        value={date}
        updateInterval={false}
        minute="2-digit"
        hour="2-digit"
      />
    </span>
  );
}

NoteDate.propTypes = {
  date: PropTypes.string.isRequired,
};

export default NoteDate;
