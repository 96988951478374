import Delta from 'quill-delta';
import markdownToDelta from 'markdown-to-quill-delta';

const isJson = s => {
  try {
    JSON.parse(s);
    return true;
  } catch (e) {
    return false;
  }
};

export const convertMarkdownToDelta = note => {
  return isJson(note) ? new Delta(JSON.parse(note)) : markdownToDelta(note);
};
