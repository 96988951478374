// generic web socket shape

export const websocketMessageContext = {
  meeting: 'meeting',
  note: 'note',
};

export const createWebSocketMessage = (type, context, payload) => ({
  version: '1',
  timestamp: Date.now(),
  type,
  context,
  payload,
});
