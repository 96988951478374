import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import * as Sentry from '@sentry/react';

import { getUser, getUserId } from '../User/selectors';

import memberStatus from 'DataLayer/Constants/workspaceMemberStatus';
import { getCollaboratorsInternal } from './Collaborators/collaboratorsSelectors';
import {
  getTeamSpaceSortType,
  getSingleSpaceSortType,
  getArchivedSpaceSortType,
  getChannelPositionsByWorkspaceId,
} from '../UI/selectors';
import { SPACES_LIST_TYPE } from '../UI';
import { SORT_TYPE } from '.';

export const getSpacesInternal = state => state.spaces.spaces;
export const spaceById = (state, id) => state.spaces.spaces[id];

export const getSpacesObject = createSelector(
  [getSpacesInternal],
  spaces => spaces
);

export const getSpaces = createSelector(
  [getSpacesInternal, getUser],
  (spaces, user) =>
    Object.values(spaces).map(space => ({
      ...space,
      IsOwner: space.OwnerEmail === user.UserID,
    }))
);

const getCollaboratorEmail = (space, collaborators) => {
  try {
    return space.IsOwner
      ? collaborators[space.Collaborators[0]].Email
      : collaborators[space.Owner].Email;
  } catch (ex) {
    return null;
  }
};

export const getSpaceById = createSelector(
  [spaceById, getUser],
  (space, user) => {
    if (space) {
      return {
        ...space,
        IsOwner: space.OwnerEmail === user.UserID,
      };
    }
    return {};
  }
);

export const makeGetSpaceById = () => {
  return createSelector([spaceById, getUser], (space, user) => {
    if (space) {
      return {
        ...space,
        IsOwner: space.OwnerEmail === user.UserID,
      };
    }
    return {};
  });
};

export const getRemoteDesktopEnabledChannelId = createSelector(
  [getSpaceById],
  space => {
    return space?.ACL?.ShareDesktop;
  }
);

export const getCanShareFolderBySpaceId = createSelector(
  [getSpaceById],
  space => {
    return space?.ACL?.ShareFolder;
  }
);

export const getMaximumMeetingParticipants = createSelector(
  [getSpaceById],
  space => {
    //TODO: Move value to Workspace
    return space?.ACL?.MaximumMeetingParticipants;
  }
);

export const getSpaceByIdWithCollaboratorEmail = createSelector(
  [getSpaceById, getCollaboratorsInternal],
  (space, collaborators) => {
    if (space) {
      return {
        ...space,
        CollaboratorEmail: getCollaboratorEmail(space, collaborators),
      };
    }
    return {};
  }
);

export const getSpacesOrderedByLastUpdate = createSelector(
  [getSpaces, (_, workspaceId) => workspaceId],
  (spaces, workspaceId) => {
    const filteredSpaces = spaces.filter(
      space => space.WorkspaceId === workspaceId
    );
    return orderBy(
      filteredSpaces,
      ['ProvisionalSpace', 'LastUpdateTimestamp'],
      ['desc', 'desc']
    );
  }
);

export const getOrderedChannels = createSelector(
  [
    getSpaces,
    getTeamSpaceSortType,
    getSingleSpaceSortType,
    getArchivedSpaceSortType,
    getChannelPositionsByWorkspaceId,
  ],
  (
    channels,
    teamSpaceSortType,
    singleSpaceSortType,
    archivedSpaceSortType,
    channelPositions = []
  ) => {
    const teamChannels = channels.filter(
      channel => !channel.IsChat && !channel.Filtered
    );
    const chats = channels.filter(chat => chat.IsChat && !chat.Filtered);
    const archivedChannels = channels.filter(space => space.Filtered);

    let teamChannelsOrdered;
    if (teamSpaceSortType === SORT_TYPE.custom) {
      teamChannelsOrdered = teamChannels.sort(
        (a, b) =>
          channelPositions[SPACES_LIST_TYPE.team]?.indexOf(a.Id) -
          channelPositions[SPACES_LIST_TYPE.team]?.indexOf(b.Id)
      );
    } else {
      teamChannelsOrdered = orderBy(
        teamChannels,
        [channel => channel.IsDefault, teamSpaceSortType.column],
        ['desc', teamSpaceSortType.direction]
      );
    }

    let singleChannelsOrdered;
    if (singleSpaceSortType === SORT_TYPE.custom) {
      singleChannelsOrdered = chats.sort(
        (a, b) =>
          channelPositions[SPACES_LIST_TYPE.single]?.indexOf(a.Id) -
          channelPositions[SPACES_LIST_TYPE.single]?.indexOf(b.Id)
      );
    } else {
      singleChannelsOrdered = orderBy(
        chats,
        singleSpaceSortType.column,
        singleSpaceSortType.direction
      );
    }

    let archivedChannelsOrdered;
    if (archivedSpaceSortType === SORT_TYPE.custom) {
      archivedChannelsOrdered = archivedChannels.sort(
        (a, b) =>
          channelPositions[SPACES_LIST_TYPE.archived]?.indexOf(a.Id) -
          channelPositions[SPACES_LIST_TYPE.archived]?.indexOf(b.Id)
      );
    } else {
      archivedChannelsOrdered = orderBy(
        archivedChannels,
        archivedSpaceSortType.column,
        archivedSpaceSortType.direction
      );
    }

    return {
      channels: teamChannelsOrdered,
      chats: singleChannelsOrdered,
      archived: archivedChannelsOrdered,
    };
  }
);

export const getOrderedChannelsByWorkspaceId = createSelector(
  [getOrderedChannels, (_, workspaceId) => workspaceId],
  (channels, workspaceId) => {
    return {
      channels: channels.channels.filter(
        channel => channel.WorkspaceId === workspaceId
      ),
      chats: channels.chats.filter(
        channel => channel.WorkspaceId === workspaceId
      ),
      archived: channels.archived.filter(
        channel => channel.WorkspaceId === workspaceId
      ),
    };
  }
);

export const getAllChannelsOrdered = createSelector(
  [getOrderedChannels],
  channels => [...channels.channels, ...channels.chats, ...channels.archived]
);

export const getOrderedChats = createSelector(
  [getOrderedChannels, getCollaboratorsInternal, getUserId],
  (channels, collaborators, userId) => {
    /**
     * Include archived chats
     */
    const allChats = channels.archived
      .filter(c => c.IsChat)
      .concat(channels.chats);
    return allChats.map(chat => {
      try {
        const collaboratorUserId = chat.IsOwner
          ? collaborators[chat.Collaborators[0]].UserId
          : collaborators[chat.Owner].UserId;
        return { ...chat, collaboratorUserId };
      } catch (error) {
        Sentry.captureException(error);
        return chat;
      }
    });
  }
);

export const getOrderedChatsByWorkspaceId = createSelector(
  [getOrderedChats, (_, workspaceId) => workspaceId],
  (chats, workspaceId) => chats.filter(chat => chat.WorkspaceId === workspaceId)
);

export const getGeneralChannelByWorkspaceId = createSelector(
  [getSpaces, (_, workspaceId) => workspaceId],
  (spaces, workspaceId) => {
    return spaces.find(
      space => space.WorkspaceId === workspaceId && space.IsDefault
    );
  }
);

export const getSpacesOrderedByName = createSelector(
  [getSpaces, (_, workspaceId) => workspaceId],
  (spaces, workspaceId) => {
    const filteredSpaces = spaces.filter(
      space => space.WorkspaceId === workspaceId
    );
    return sortBy(filteredSpaces, ['NameLowerCase']);
  }
);

export const getChannelsOrderedByName = createSelector(
  [getSpaces, (_, workspaceId) => workspaceId],
  (spaces, workspaceId) => {
    const filteredSpaces = spaces
      .filter(space => space.WorkspaceId === workspaceId)
      .filter(space => !space.IsChat);
    return orderBy(filteredSpaces, ['NameLowerCase']);
  }
);

export const getFirstChannelByWorkspaceId = createSelector(
  [getGeneralChannelByWorkspaceId, getChannelsOrderedByName],
  (generalChannel, channels) => {
    return generalChannel || channels?.[0];
  }
);

export const makeGetSingleSpaceByEmailAndWorkspaceId = () =>
  createSelector(
    [
      getSpaces,
      getUser,
      (_, props) => props.workspaceId,
      (_, props) => props.email,
    ],
    (spaces, user, workspaceId, email) => {
      if (!spaces) return {};

      if (user.UserID === email) return {};

      return spaces
        .filter(space => space.WorkspaceId === workspaceId)
        .find(space => {
          if (!space.IsChat) return false;
          if (space.IsConversation) return false;
          if (space.OwnerEmail === email) {
            return true;
          }
          if (
            space.Collaborators.some(
              collaborator => collaborator.indexOf(email) > -1
            )
          ) {
            return true;
          }
          return false;
        });
    }
  );

export const getChannelsWithMemberInfo = createSelector(
  [getSpaces, getCollaboratorsInternal, (_, workspaceId) => workspaceId],
  (spaces, collaborators, workspaceId) => {
    const filteredSpaces = spaces
      .filter(space => space.WorkspaceId === workspaceId)
      .map(space => {
        return {
          ...space,
          Owner: collaborators[space.Owner],
          Collaborators: space.Collaborators.map(collaborator => ({
            ...collaborators[collaborator],
          })),
        };
      });
    return filteredSpaces;
  }
);

const getPrivateChats = createSelector(
  [getSpaces, getUser, getCollaboratorsInternal],
  (spaces, user, collaborators) => {
    const filteredSpaces = spaces
      .filter(space => space.WorkspaceId === null)
      .map(space => {
        return {
          ...space,
          CollaboratorEmail: getCollaboratorEmail(space, collaborators),
        };
      });
    return orderBy(filteredSpaces, ['Name']);
  }
);

export const getActivePrivateChats = createSelector(
  [getPrivateChats],
  privateChats =>
    privateChats.filter(
      privateChat =>
        get(privateChat, 'Status.CurrentUserStatus') === memberStatus.JOINED
    )
);

export const getPendingPrivateChats = createSelector(
  [getPrivateChats],
  privateChats =>
    privateChats.filter(
      privateChat =>
        get(privateChat, 'Status.CurrentUserStatus') === memberStatus.INVITED
    )
);
