import { filesize } from 'filesize';
import stringGen from 'Helpers/Strings/generator.js';

export const SIZES = {
  SMALL: 800,
  MEDIUM: 1200,
  ORIGINAL: 0,
};

const canResizeFile = (type = '') => type.startsWith('image');

export function convertFilesToObjects(files, status) {
  return Array.from(files)
    .sort((a, b) => a.size - b.size)
    .map(file => {
      /*
      folders received by react-dropzone will have a relativePath property
      folders received using <input webkitdirectory /> will have a webkitRelativePath property
    */
      const relativePath = file.relativePath || file.webkitRelativePath;
      return {
        id: stringGen(20),
        name: file.name,
        size: filesize(file.size, { round: 0 }),
        type: file.type,
        status,
        relativePath,
        file,
        resizable: canResizeFile(file.type),
        resize: SIZES.ORIGINAL,
      };
    });
}
