import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getOtixoEnvironment, isOtixoEnvProduction } from 'config/config';
import { isNodeEnvDevelopment } from 'config/config';

export const init = () => {
  if (isNodeEnvDevelopment()) {
    console.debug('Sentry excluded in development');
  } else {
    console.debug('Sentry running');
    Sentry.init({
      dsn: 'https://5cdc21b74c5147b98b6dd4f844f51872@o37189.ingest.sentry.io/5597062',
      release: process.env.REACT_APP_VERSION,
      environment: getOtixoEnvironment(),
      //integrations: [new Integrations.BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: isOtixoEnvProduction() ? 0.2 : 1.0,
      normalizeDepth: 10, // Or however deep you want your state context to be.
      beforeSend: (event, hint) => {
        try {
          if (shouldIgnoreEvent(event)) {
            return null;
          }
        } catch (error) {
          return event;
        }

        return event;
      },
    });
  }
};

const SENTRY_TYPES_TO_IGNORE = [
  'UnhandledRejection',
  'Request aborted',
  'DatabaseClosedError',
];
const SENTRY_VALUES_TO_IGNORE = [
  'Non-Error exception captured with keys: code, message',
  'Non-Error promise',
  'timeout of 0ms exceeded',
  'Request aborted',
  'Request failed with status code',
  'Network Error', // these are flooding Sentry with Status Code 0, probably due to user leaving/redirecting while requests are in progress
  'ResizeObserver loop limit exceeded', // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/3
];

export const shouldIgnoreEvent = event => {
  try {
    if (SENTRY_TYPES_TO_IGNORE.includes(event.exception.values[0].type)) {
      return true;
    }
    if (
      SENTRY_VALUES_TO_IGNORE.some(value =>
        event.exception.values[0].value.includes(value)
      )
    ) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const captureException = ex => Sentry.captureException(ex);
