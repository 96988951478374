import NoteText from 'Components/Notes/NoteText';
import { getNoteLink } from 'pages';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Icon, Message, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';

import {
  chatPreviewHidden,
  SIDEBAR_ITEMS,
  spaceSidebarPanelSelected,
} from 'store/UI';
import { getIsChatPreviewHidden } from 'store/UI/selectors';

import { useNotesByChannelId } from 'Hooks/data/notes/useNotes';

import styles from './Note.module.css';
import NoteHeader from 'Components/Notes/NoteHeader';

export function Note({ note, router: { push } }) {
  const dispatch = useDispatch();
  const isHidden = useSelector(state => getIsChatPreviewHidden(state, note.id));

  const { data, isLoading } = useNotesByChannelId(note.channelId);

  const onToggleClick = () => {
    dispatch(chatPreviewHidden(note.id));
  };

  const onClick = () => {
    dispatch(
      spaceSidebarPanelSelected(note.channelId, SIDEBAR_ITEMS.notes, true)
    );
    push(getNoteLink(note.channelId, note.id));
  };

  if (isLoading) {
    return null;
  }

  const isNoteRemoved = !data.some(n => n.id === note.id);

  return (
    <div>
      <p>
        <FormattedMessage id="Note.Created" />
      </p>
      <p>
        <FormattedMessage id="Note.Updated" />
      </p>
      <p>
        <FormattedMessage id="Note.Removed" />
        <Icon
          data-testid="toggle-icon"
          title={isHidden ? 'Show preview' : 'Hide preview'}
          className={styles.toggle}
          link
          size="small"
          name={isHidden ? 'chevron right' : 'chevron down'}
          onClick={onToggleClick}
        />
      </p>
      {!isHidden && (
        <Segment.Group className={styles.note}>
          {isNoteRemoved && (
            <Message size="small" className={styles.note} attached>
              <FormattedMessage id="Note.HasBeenRemoved" />
            </Message>
          )}
          {!isNoteRemoved && <NoteHeader note={note} />}
          <Segment
            disabled={isNoteRemoved}
            attached
            onClick={() => {
              if (isNoteRemoved) {
                return;
              }
              onClick();
            }}
            className={classNames(styles.note, {
              [styles.canClick]: !isNoteRemoved,
            })}
          >
            <NoteText note={note.note} truncated />
          </Segment>
        </Segment.Group>
      )}
    </div>
  );
}

Note.propTypes = {
  note: PropTypes.string.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Note);
