import * as modalTypes from 'Components/Modals/modalTypes';
import products from 'Constants/products';
import orderBy from 'lodash/orderBy';

import generateRandomString from 'Helpers/Strings/generator';
import { CLEAR_COMPLETED } from '../Upload/uploadSaga';
import { getSpaceById } from '../Spaces/selectors';
import loadResource from 'store/Resources/loadResource';
import { getResourceById } from 'store/Resources/selectors';
import selectSource from 'store/Copy/selectSource';
import { getNotificationById } from 'store/Notifications/selectors';
import { isFileNotification } from 'Constants/notificationType';
import { removeAll } from 'store/LinkedItems/share';
import { LINKED_ITEM_SORT_COLUMNS } from 'store/Sorting';
import RESOURCES_SORT_COLUMNS from 'store/Resources/resourcesSortColumns';
import { isWorkspaceFree } from 'store/Workspaces/selectors';

export function hideModal(modalType) {
  return {
    type: 'HIDE_MODAL',
    modalType,
  };
}

export function hideAllModals() {
  return {
    type: 'HIDE_ALL_MODALS',
  };
}

export function showModal(modalType, props) {
  return {
    id: generateRandomString(10),
    type: 'SHOW_MODAL',
    modalType,
    props,
  };
}

export function showUpgradeModal(product, onCancel) {
  return showModal(modalTypes.UPGRADE, { product, onCancel });
}

export function hideUpgradeModal() {
  return hideModal(modalTypes.UPGRADE);
}

export function showWorkspaceUpgradeModal(workspaceId, product, onCancel) {
  return showModal(modalTypes.WORKSPACE_UPGRADE, {
    workspaceId,
    product,
    onCancel,
  });
}

export function hideWorkspaceUpgradeModal() {
  return hideModal(modalTypes.WORKSPACE_UPGRADE);
}

export function showPlanChangeNotAllowedModal(incompatibleProducts, planCode) {
  return showModal(modalTypes.PLAN_CHANGE_NOT_ALLOWED, {
    incompatibleProducts,
    planCode,
  });
}

export function hidePlanChangeNotAllowedModal() {
  return hideModal(modalTypes.PLAN_CHANGE_NOT_ALLOWED);
}
/*
export function showBuyBandwidthModal(copySize) {
  return showModal(modalTypes.BUY_BANDWIDTH, { copySize });
}

export function hideBuyBandwidthModal() {
  return hideModal(modalTypes.BUY_BANDWIDTH);
}

export function showBuyBandwidthCompleteModal() {
  return showModal(modalTypes.BUY_BANDWIDTH_COMPLETE);
}

export function hideBuyBandwidthCompleteModal() {
  return hideModal(modalTypes.BUY_BANDWIDTH_COMPLETE);
}
*/
export function showTransferSubscriptionModal() {
  return showModal(modalTypes.GET_TRANSFER_SUBSCRIPTION);
}

export function hideTransferSubscriptionModal() {
  return hideModal(modalTypes.GET_TRANSFER_SUBSCRIPTION);
}

export function showBandwidthExceededFreeModal() {
  return showModal(modalTypes.BANDWIDTH_EXCEEDED);
}

export function hideBandwidthExceededFreeModal() {
  return hideModal(modalTypes.BANDWIDTH_EXCEEDED);
}

export const showBandwidthExceededModal = copySize => (dispatch, getState) => {
  dispatch(showBandwidthExceededFreeModal(copySize));
};

export function showNoQuotaModal() {
  return showModal(modalTypes.NO_QUOTA);
}

export function hideNoQuotaModal() {
  return hideModal(modalTypes.NO_QUOTA);
}

export const showNoSpaceQuotaModal = spaceId => (dispatch, getState) => {
  const state = getState();
  const space = getSpaceById(state, spaceId);
  dispatch(
    showModal(modalTypes.NO_SPACE_QUOTA, { workspaceId: space.WorkspaceId })
  );
};

export function hideNoSpaceQuotaModal() {
  return hideModal(modalTypes.NO_SPACE_QUOTA);
}

export function showAppsExceededModal(onCancel) {
  // return showUpgradeModal(products.max_integration_number, onCancel);
  return showModal(modalTypes.APPS_EXCEEDED, { onCancel });
}

export function hideAppsExceededModal() {
  return hideModal(modalTypes.APPS_EXCEEDED);
}

export function showAdapterRetiredModal(adapter) {
  return showModal(modalTypes.ADAPTER_RETIRED, { adapter });
}

export function hideAdapterRetiredModal() {
  return hideModal(modalTypes.ADAPTER_RETIRED);
}
export function showBandwidthInformationModal(onContinue, onCancel) {
  return showModal(modalTypes.BANDWIDTH_INFORMATION, { onContinue, onCancel });
}

export function hideBandwidthInformationModal() {
  return hideModal(modalTypes.BANDWIDTH_INFORMATION);
}

export function showShareFolderInformationModal(onContinue, onCancel) {
  return showModal(modalTypes.SHARE_FOLDER_INFORMATION, {
    onContinue,
    onCancel,
  });
}

export function hideShareFolderInformationModal() {
  return hideModal(modalTypes.SHARE_FOLDER_INFORMATION);
}

export function showPublicLinkSizeRestrictedModal(onCancel) {
  return showModal(modalTypes.PUBLIC_LINK_SIZE_RESTRICTED, { onCancel });
}

export function hidePublicLinkSizeRestrictedModal() {
  return hideModal(modalTypes.PUBLIC_LINK_SIZE_RESTRICTED);
}

export const showTransferFilesRestrictedModal = workspaceId =>
  showWorkspaceUpgradeModal(workspaceId, products.transfer_files_in_workspace);

export function showCloudDownloadRestrictedModal(onCancel) {
  return showUpgradeModal(products.download_from_clouds_allowed, onCancel);
}

export function showCloudCopyRestrictedModal(withDelete, spaceId, onCancel) {
  return showModal(modalTypes.CLOUD_COPY_RESTRICTED, {
    withDelete,
    spaceId,
    onCancel,
  });
}

export function hideCloudCopyRestrictedModal() {
  return hideModal(modalTypes.CLOUD_COPY_RESTRICTED);
}

export function showFolderShareRestrictedModal(workspaceId, onCancel) {
  return showWorkspaceUpgradeModal(
    workspaceId,
    products.share_folder_to_space,
    onCancel
  );
}

export const showExportChannelRestrictedModal = workspaceId =>
  showWorkspaceUpgradeModal(workspaceId, products.export_channel);

export const showExportWorkspaceRestrictedModal = workspaceId => {
  return showWorkspaceUpgradeModal(workspaceId, products.export_workspace);
};

export const showShareAcrosssWorkspaceRestrictedModal = workspaceId => {
  return showWorkspaceUpgradeModal(
    workspaceId,
    products.share_across_workspaces
  );
};

export const showAdapterList = spaceId => (dispatch, getState) => {
  dispatch(showModal(modalTypes.ADAPTERS, { spaceId }));
  if (spaceId) {
    dispatch(hideAddToSpaceModal());
  }
};

export const hideAdapterList = spaceId => (dispatch, getState) => {
  dispatch(hideModal(modalTypes.ADAPTERS));
  if (spaceId) {
    dispatch(showAddToSpaceModal(spaceId));
  }
};

export const showAdapter = (adapter, spaceId) => dispatch => {
  if (adapter.Retired) {
    dispatch(showAdapterRetiredModal(adapter));
    return;
  }
  if (adapter.Available) {
    dispatch(hideAdapterList());
    dispatch(showModal(modalTypes.ADAPTER, { adapter, spaceId }));
  } else {
    dispatch(hideAdapterList());
    dispatch(
      showUpgradeModal(products.restricted_clouds, () => {
        dispatch(showAdapterList());
      })
    );
  }
};

export const hideAdapter = spaceId => dispatch => {
  dispatch(hideModal(modalTypes.ADAPTER));
  if (spaceId) {
    dispatch(showAdapterList(spaceId));
  }
};

export function showReconnectAlert(repository) {
  return showModal(modalTypes.ALERT_RECONNECT, { repository });
}

export function hideReconnectAlert() {
  return hideModal(modalTypes.ALERT_RECONNECT);
}

export const convertResourcesToLinkedItem = resources =>
  resources.map(sourceItem => ({
    Resource: sourceItem,
    ResourceId: sourceItem.Id,
  }));

export const showCopyModal =
  (sourceItems = [], withDelete, spaceId) =>
  dispatch => {
    if (sourceItems?.length === 0) {
      return;
    }
    if (spaceId) {
      const linkedItems = convertResourcesToLinkedItem(sourceItems);
      dispatch(showTransferLinkedItemSelectTargetModal(spaceId, linkedItems));
    } else {
      dispatch(showModal(modalTypes.COPY, { sourceItems, withDelete }));
      dispatch(selectSource(sourceItems, withDelete));
    }
  };

export function hideCopyModal() {
  return hideModal(modalTypes.COPY);
}

export const showTransferToOtixoDriveModal =
  (sourceItems = []) =>
  dispatch => {
    if (sourceItems?.length === 0) {
      return;
    }
    dispatch(showModal(modalTypes.TRANSFER_TO_OTIXO_DRIVE, { sourceItems }));
    dispatch(selectSource(sourceItems));
  };

export function hideTransferToOtixoDriveModal() {
  return hideModal(modalTypes.TRANSFER_TO_OTIXO_DRIVE);
}

export function showResourceLink(resourceId) {
  return showModal(modalTypes.RESOURCE_LINK, { resourceId });
}

export function hideResourceLink() {
  return hideModal(modalTypes.RESOURCE_LINK);
}

export function showRemoveResourceLink(resourceId) {
  return showModal(modalTypes.RESOURCE_LINK_REMOVE, { resourceId });
}

export function hideRemoveResourceLink() {
  return hideModal(modalTypes.RESOURCE_LINK_REMOVE);
}

export function showResourceLinkSettings(resourceId) {
  return showModal(modalTypes.RESOURCE_LINK_SETTINGS, { resourceId });
}

export function hideResourceLinkSettings() {
  return hideModal(modalTypes.RESOURCE_LINK_SETTINGS);
}

export function showUploadProgressModal() {
  return showModal(modalTypes.RESOURCE_UPLOAD_PROGRESS);
}

export const hideUploadProgressModal = () => dispatch => {
  dispatch({ type: CLEAR_COMPLETED });
  dispatch(hideModal(modalTypes.RESOURCE_UPLOAD_PROGRESS));
};

export function showShareLinkedItemsProgressModal() {
  return showModal(modalTypes.SHARE_LINKED_ITEMS_PROGRESS);
}

export const hideShareLinkedItemsProgressModal = () => dispatch => {
  dispatch(removeAll());
  dispatch(hideModal(modalTypes.SHARE_LINKED_ITEMS_PROGRESS));
};

export function showTransfersProgressModal() {
  return showModal(modalTypes.TRANSFERS_PROGRESS);
}

export const hideTransfersProgressModal = () => {
  return hideModal(modalTypes.TRANSFERS_PROGRESS);
};

export function showDeleteRepositoryModal(repository) {
  return showModal(modalTypes.REPOSITORY_DELETE, { repository });
}

export function hideDeleteRepositoryModal() {
  return hideModal(modalTypes.REPOSITORY_DELETE);
}

export function showRenameRepositoryModal(repository) {
  return showModal(modalTypes.REPOSITORY_RENAME, { repository });
}

export function hideRenameRepositoryModal() {
  return hideModal(modalTypes.REPOSITORY_RENAME);
}

export function showReconnectRepositoryModal(repository, spaceId) {
  return showModal(modalTypes.REPOSITORY_RECONNECT, { repository, spaceId });
}

export function hideReconnectRepositoryModal() {
  return hideModal(modalTypes.REPOSITORY_RECONNECT);
}

export function showEditRepositoryModal(repositoryId, adapterId) {
  return showModal(modalTypes.REPOSITORY_EDIT, { repositoryId, adapterId });
}

export function hideEditRepositoryModal() {
  return hideModal(modalTypes.REPOSITORY_EDIT);
}

export function showArchiveSpaceModal(space) {
  return showModal(modalTypes.SPACE_ARCHIVE, { space });
}

export function hideArchiveSpaceModal() {
  return hideModal(modalTypes.SPACE_ARCHIVE);
}

export function showUnarchiveSpaceModal(space) {
  return showModal(modalTypes.SPACE_UNARCHIVE, { space });
}

export function hideUnarchiveSpaceModal() {
  return hideModal(modalTypes.SPACE_UNARCHIVE);
}

export function showDeleteSpaceModal(space) {
  return showModal(modalTypes.SPACE_DELETE, { space });
}

export function hideDeleteSpaceModal() {
  return hideModal(modalTypes.SPACE_DELETE);
}

export function showLeaveWorkspaceModal(workspace) {
  return showModal(modalTypes.WORKSPACE_LEAVE, { workspace });
}

export function hideLeaveWorkspaceModal() {
  return hideModal(modalTypes.WORKSPACE_LEAVE);
}

export function showWorkspaceExportStartedModal(workspace) {
  return showModal(modalTypes.WORKSPACE_EXPORT_STARTED, { workspace });
}

export function hideWorkspaceExportStartedModal() {
  return hideModal(modalTypes.WORKSPACE_EXPORT_STARTED);
}

export function showLeaveSpaceModal(space) {
  return showModal(modalTypes.SPACE_LEAVE, { space });
}

export function hideLeaveSpaceModal() {
  return hideModal(modalTypes.SPACE_LEAVE);
}

export function showLeavePrivateChatModal(space) {
  return showModal(modalTypes.PRIVATECHAT_LEAVE, { space });
}

export function hideLeavePrivateChatModal() {
  return hideModal(modalTypes.PRIVATECHAT_LEAVE);
}

export function showEditSpaceModal(space) {
  return showModal(modalTypes.SPACE_RENAME, { space });
}

export function hideEditSpaceModal() {
  return hideModal(modalTypes.SPACE_RENAME);
}

export const showExportChannelModal = space => (dispatch, getState) => {
  if (space.ACL.ExportChannel) {
    dispatch(showModal(modalTypes.CHANNEL_EXPORT, { space }));
  } else {
    dispatch(showExportChannelRestrictedModal(space.WorkspaceId));
  }
};

export function hideExportChannelModal() {
  return hideModal(modalTypes.CHANNEL_EXPORT);
}

export function showChangeSpaceAvatarModal(space) {
  return showModal(modalTypes.SPACE_CHANGE_AVATAR, { space });
}

export function hideChangeSpaceAvatarModal() {
  return hideModal(modalTypes.SPACE_CHANGE_AVATAR);
}

export function showRenameResourceModal(resource) {
  return showModal(modalTypes.RESOURCE_RENAME, { resource });
}

export function hideRenameResourceModal() {
  return hideModal(modalTypes.RESOURCE_RENAME);
}

export function showDeleteResourceModal(resources, parentId) {
  return showModal(modalTypes.RESOURCE_DELETE, { resources, parentId });
}

export function hideDeleteResourceModal() {
  return hideModal(modalTypes.RESOURCE_DELETE);
}
/*
export function showAddMemberToSpaceModal(collaborator) {
  return showModal(modalTypes.ADD_MEMBER_TO_SPACE, { collaborator });
}

export function hideAddMemberToSpaceModal() {
  return hideModal(modalTypes.ADD_MEMBER_TO_SPACE);
}
*/
export function showRenameLinkedItemModal(spaceId, linkedItem) {
  return showModal(modalTypes.LINKEDITEM_RENAME, { spaceId, linkedItem });
}

export function hideRenameLinkedItemModal() {
  return hideModal(modalTypes.LINKEDITEM_RENAME);
}

export function showDeleteLinkedItemModal(spaceId, linkedItems) {
  return showModal(modalTypes.LINKEDITEM_DELETE, { spaceId, linkedItems });
}

export function hideDeleteLinkedItemModal() {
  return hideModal(modalTypes.LINKEDITEM_DELETE);
}

export function showTransferLinkedItemModal(spaceId, source, target) {
  return showModal(modalTypes.LINKEDITEM_TRANSFER, {
    spaceId,
    source,
    target,
  });
}

export function hideTransferLinkedItemModal() {
  return hideModal(modalTypes.LINKEDITEM_TRANSFER);
}

export function showTransferLinkedItemSelectTargetModal(spaceId, sources) {
  return showModal(modalTypes.TRANSFER_LINKEDITEM_SELECT_TARGET, {
    spaceId,
    sources,
  });
}

export function hideTransferLinkedItemSelectTargetModal() {
  return hideModal(modalTypes.TRANSFER_LINKEDITEM_SELECT_TARGET);
}

export function showDeleteCollaboratorModal(spaceId, email) {
  return showModal(modalTypes.COLLABORATOR_REMOVE, { spaceId, email });
}

export function hideDeleteCollaboratorModal() {
  return hideModal(modalTypes.COLLABORATOR_REMOVE);
}
/*
export function showChangeToSingleGuestErrorModal(collaborator) {
  return showModal(modalTypes.COLLABORATOR_CHANGE_TO_SINGLE_GUEST_ERROR, { collaborator });
}

export function hideChangeToSingleGuestErrorModal() {
  return hideModal(modalTypes.COLLABORATOR_CHANGE_TO_SINGLE_GUEST_ERROR);
}
*/
export function showChangeToSingleGuestModal(workspaceId, member) {
  return showModal(modalTypes.MEMBER_CHANGE_TO_SINGLE_GUEST, {
    workspaceId,
    member,
  });
}

export function hideChangeToSingleGuestModal() {
  return hideModal(modalTypes.MEMBER_CHANGE_TO_SINGLE_GUEST);
}

export function showChangeToMemberModal(workspaceId, member) {
  return showModal(modalTypes.MEMBER_CHANGE_TO_MEMBER, { workspaceId, member });
}

export function hideChangeToMemberModal() {
  return hideModal(modalTypes.MEMBER_CHANGE_TO_MEMBER);
}

export function showDeleteWorkspaceMemberModal(member) {
  return showModal(modalTypes.WORKSPACE_MEMBER_REMOVE, { member });
}

export function hideDeleteWorkspaceMemberModal() {
  return hideModal(modalTypes.WORKSPACE_MEMBER_REMOVE);
}

export function showAddWorkspaceMemberModal(workspaceId, members = []) {
  return showModal(modalTypes.WORKSPACE_MEMBER_ADD, { workspaceId, members });
}

export function hideAddWorkspaceMemberModal() {
  return hideModal(modalTypes.WORKSPACE_MEMBER_ADD);
}

export const showResourceAddFolder =
  (parentId, spaceId) => (dispatch /*, getState*/) => {
    // const state = getState();

    // if user is free and not in otixo drive, show upgrade dialog

    //  const canUpload = getUserFeatureUploadToClouds(state);

    //  const repository = getRepositoryByResourceId(state, parentId);

    /*
    Always allow upload if:
    - User is in a Space
    - User's plan allows uploading
    - User is inside their Otixo Drive
  */

    //  if (canUpload || spaceId || repository.AdapterId === 'otixo-drive') {
    dispatch(showModal(modalTypes.RESOURCE_ADD_FOLDER, { parentId, spaceId }));
    ///  } else {
    //    dispatch(showCloudUploadRestrictedModal());
    //  }
  };

export function hideResourceAddFolderModal() {
  return hideModal(modalTypes.RESOURCE_ADD_FOLDER);
}

export function showSpaceEventsModal(spaceId) {
  return showModal(modalTypes.SPACE_EVENTS, { spaceId });
}

export function hideSpaceEventsModal() {
  return hideModal(modalTypes.SPACE_EVENTS);
}

export const showPreviewerForSingleResource =
  (id, spaceId) => async (dispatch, getState) => {
    const state = getState();
    const resource = getResourceById(state, id);
    if (!resource.Id) {
      await dispatch(loadResource(id));
    }
    dispatch(
      showModal(modalTypes.SINGLE_RESOURCE_PREVIEW, {
        id,
        spaceId,
      })
    );
  };

export const showPreviewerForResources = (
  id,
  parentId,
  spaceId,
  isGuest,
  readOnly
) => {
  return showModal(modalTypes.RESOURCES_PREVIEW, {
    id,
    parentId,
    spaceId,
    isGuest,
    readOnly,
  });
};

export const showPreviewerForLinkedItems = (id, spaceId, isGuest) => {
  return showModal(modalTypes.LINKED_ITEM_PREVIEW, {
    id,
    spaceId,
    isGuest,
  });
};

export const showPreviewerForChatItems = (
  resourceId,
  spaceId,
  showCommentsSidebar
) => {
  return showModal(modalTypes.CHAT_PREVIEW, {
    resourceId,
    spaceId,
    showCommentsSidebar,
  });
};

export function hideViewSingleResourceModal() {
  return hideModal(modalTypes.SINGLE_RESOURCE_PREVIEW);
}

export function hideViewResourceModal() {
  return hideModal(modalTypes.RESOURCES_PREVIEW);
}

export function hideViewChatModal() {
  return hideModal(modalTypes.CHAT_PREVIEW);
}

export function hideViewLinkedItemModal() {
  return hideModal(modalTypes.LINKED_ITEM_PREVIEW);
}

export const showUploadFiles = (parentId, spaceId) => {
  return showModal(modalTypes.RESOURCE_UPLOAD_FILES, {
    parentId,
    spaceId,
  });
};

export function hideUploadFiles() {
  return hideModal(modalTypes.RESOURCE_UPLOAD_FILES);
}

export function showDecryptAndDownloadModal(resourceId, spaceId, name) {
  return showModal(modalTypes.DECRYPT_AND_DOWNLOAD, {
    resourceId,
    spaceId,
    name,
  });
}

export function hideDecryptAndDownloadModal() {
  return hideModal(modalTypes.DECRYPT_AND_DOWNLOAD);
}

export function showDecryptAndDownloadPublicModal(
  resourceId,
  publicLinkId,
  publicPassword
) {
  return showModal(modalTypes.DECRYPT_AND_DOWNLOAD_PUBLIC, {
    resourceId,
    publicLinkId,
    publicPassword,
  });
}

export function hideDecryptAndDownloadPublicModal() {
  return hideModal(modalTypes.DECRYPT_AND_DOWNLOAD_PUBLIC);
}

export function showPasswordForDecryptModal(resourceId, spaceId, hint) {
  return showModal(modalTypes.PASSWORD_FOR_DECRYPT, {
    resourceId,
    spaceId,
    hint,
  });
}

export function hidePasswordForDecryptModal() {
  return hideModal(modalTypes.PASSWORD_FOR_DECRYPT);
}

export function showPasswordForDecryptPublicModal(resourceId, publicLinkId) {
  return showModal(modalTypes.PASSWORD_FOR_DECRYPT_PUBLIC, {
    resourceId,
    publicLinkId,
  });
}

export function hidePasswordForDecryptPublicModal() {
  return hideModal(modalTypes.PASSWORD_FOR_DECRYPT_PUBLIC);
}

export function showAddToSpaceModal(spaceId) {
  return showModal(modalTypes.ADD_ITEM_TO_SPACE, { spaceId });
}

export function hideAddToSpaceModal() {
  return hideModal(modalTypes.ADD_ITEM_TO_SPACE);
}

export function showOnboardingLearnMoreSpaces() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_SPACES);
}

export function hideOnboardingLearnMoreSpaces() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_SPACES);
}

export function showOnboardingLearnMoreDrives() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_DRIVES);
}

export function hideOnboardingLearnMoreDrives() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_DRIVES);
}

export function showOnboardingLearnMoreDriveChannels() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_DRIVE_CHANNELS);
}

export function hideOnboardingLearnMoreDriveChannels() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_DRIVE_CHANNELS);
}

export function showOnboardingLearnMoreTeamChannels() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_TEAM_CHANNELS);
}

export function hideOnboardingLearnMoreTeamChannels() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_TEAM_CHANNELS);
}

export function showOnboardingLearnMoreSingleChannels() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_SINGLE_CHANNELS);
}

export function hideOnboardingLearnMoreSingleChannels() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_SINGLE_CHANNELS);
}

export function showOnboardingLearnMoreTeamChannel() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_TEAM_CHANNEL);
}

export function hideOnboardingLearnMoreTeamChannel() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_TEAM_CHANNEL);
}

export function showOnboardingComments() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_COMMENTS);
}
export function hideOnboardingComments() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_COMMENTS);
}

export function showOnboardingOtixoSpace() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_OTIXO_SPACE);
}

export function hideOnboardingOtixoSpace() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_OTIXO_SPACE);
}

export function showOnboardingOtixoDrive() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_OTIXO_DRIVE);
}

export function hideOnboardingOtixoDrive() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_OTIXO_DRIVE);
}

export function showOnboardingLearnMoreSingleSpaces() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_SINGLESPACES);
}

export function hideOnboardingLearnMoreSingleSpaces() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_SINGLESPACES);
}

export function showAddSpaceModal(items) {
  return showModal(modalTypes.SPACE_ADD, { items });
}

export function hideAddSpaceModal() {
  return hideModal(modalTypes.SPACE_ADD);
}
/*
export function showAddSingleSpaceModal(items) {
  return showModal(modalTypes.SINGLE_SPACE_ADD, { items });
}

export function hideAddSingleSpaceModal() {
  return hideModal(modalTypes.SINGLE_SPACE_ADD);
}
*/
export function showAddTeamSpaceModal(workspaceId, items) {
  return showModal(modalTypes.TEAM_SPACE_ADD, { workspaceId, items });
}

export function hideAddTeamSpaceModal() {
  return hideModal(modalTypes.TEAM_SPACE_ADD);
}

export function showAddWorkspaceModal() {
  return showModal(modalTypes.WORKSPACE_ADD);
}

export function hideAddWorkspaceModal() {
  return hideModal(modalTypes.WORKSPACE_ADD);
}

/*
export function showDeleteChatModal(space) {
  return showModal(modalTypes.DELETE_CHAT, { space });
}

export function hideDeleteChatModal() {
  return hideModal(modalTypes.DELETE_CHAT);
}
*/
export function showOnboardingLearnMoreSingleChannel() {
  return showModal(modalTypes.ONBOARDING_LEARNMORE_SINGLE_CHANNEL);
}

export function hideOnboardingLearnMoreSingleChannel() {
  return hideModal(modalTypes.ONBOARDING_LEARNMORE_SINGLE_CHANNEL);
}

/*
// #6262
export const showAddAccount = () => (dispatch, getState) => {
  const state = getState();
  if (getUserFeatureAddAccount(state)) {
    return dispatch(showModal(modalTypes.ACCOUNTS_ADD));
  }
  return dispatch(showAddAccountRestrictedModal());
};

export function hideAddAccount() {
  return hideModal(modalTypes.ACCOUNTS_ADD);
}

export function showDisconnectAccount(userId, groupId) {
  return showModal(modalTypes.ACCOUNTS_DISCONNECT, { userId, groupId });
}

export function hideDisconnectAccount() {
  return hideModal(modalTypes.ACCOUNTS_DISCONNECT);
}

export function showRenameAccount(userId, groupId, name) {
  return showModal(modalTypes.ACCOUNTS_RENAME, { userId, groupId, name });
}

export function hideRenameAccount() {
  return hideModal(modalTypes.ACCOUNTS_RENAME);
}

export function showAccountChangeAvatar() {
  return showModal(modalTypes.ACCOUNTS_CHANGE_AVATAR);
}

export function hideAccountChangeAvatar() {
  return hideModal(modalTypes.ACCOUNTS_CHANGE_AVATAR);
}

export function showViewWorkGroupMembers() {
  return showModal(modalTypes.ACCOUNTS_VIEW_MEMBERS);
}

export function hideViewWorkGroupMembers() {
  return hideModal(modalTypes.ACCOUNTS_VIEW_MEMBERS);
}
*/

export const showSpaceChatDeleteMessage = notificationId => {
  return showModal(modalTypes.SPACE_CHAT_DELETE_MESSAGE, { notificationId });
};

export function hideSpaceChatDeleteMessage() {
  return hideModal(modalTypes.SPACE_CHAT_DELETE_MESSAGE);
}

export function showDeleteFileComment(spaceId, resourceId, commentId) {
  return showModal(modalTypes.FILE_COMMENTS_DELETE, {
    spaceId,
    resourceId,
    commentId,
  });
}

export function hideDeleteFileComment() {
  return hideModal(modalTypes.FILE_COMMENTS_DELETE);
}

export const showShareFolderRestrictedModal =
  spaceId => (dispatch, getState) => {
    const state = getState();
    const space = getSpaceById(state, spaceId);

    if (space.IsConversation) {
      dispatch(showConversationFeatureRestrictionModal());
    } else {
      // if the user owns the space, but doesn't have permission to perform an action, it's because they don't have a high enough subscription
      dispatch(showFolderShareRestrictedModal(space.WorkspaceId));
    }
  };

export const showAddSpaceFolder = spaceId => (dispatch, getState) => {
  const state = getState();

  const space = getSpaceById(state, spaceId);

  const isWorkspaceFreeResult = isWorkspaceFree(state, space.WorkspaceId);

  if (!isWorkspaceFreeResult) {
    dispatch(showModal(modalTypes.ADD_SPACE_FOLDER, { spaceId }));
  } else if (space.IsConversation) {
    dispatch(showConversationFeatureRestrictionModal());
  } else {
    dispatch(
      showWorkspaceUpgradeModal(space.WorkspaceId, products.create_space_folder)
    );
  }
};

export function hideAddSpaceFolderModal() {
  return hideModal(modalTypes.ADD_SPACE_FOLDER);
}

export const showLinkedItemAccessRightsRestrictedModal =
  spaceId => (dispatch, getState) => {
    const state = getState();
    const space = getSpaceById(state, spaceId);
    dispatch(
      showWorkspaceUpgradeModal(
        space.WorkspaceId,
        products.space_access_control
      )
    );
  };

export const showShareLinkedItemsWithChannelModal =
  (linkedItems, channelId) => (dispatch, getState) => {
    const linkedItemsOrdered = orderBy(
      linkedItems,
      LINKED_ITEM_SORT_COLUMNS.Created
    );

    const resources = linkedItemsOrdered.map(linkedItem => linkedItem.Resource);

    dispatch(showShareWithChannelModal(resources, channelId));
  };

export const showShareResourcesWithChannelModal =
  (resources, channelId) => (dispatch, getState) => {
    const resourcesOrdered = orderBy(
      resources,
      RESOURCES_SORT_COLUMNS.Modified
    );

    dispatch(showShareWithChannelModal(resourcesOrdered, channelId));
  };

export const showShareWithChannelModal =
  (resources, channelId) => (dispatch, getState) => {
    const state = getState();
    const channel = getSpaceById(state, channelId);

    dispatch(
      showShareFilesAndMessagesWithChannelModal({
        workspaceId: channel?.WorkspaceId,
        resourceIds: resources.map(resource => resource.Id),
      })
    );
  };

export const showShareMessagesWithChannelModal =
  (workspaceId, notificationIds) => (dispatch, getState) => {
    const state = getState();

    const { resourceIds, notificationIdsFinal } = notificationIds.reduce(
      (prev, current) => {
        const notification = getNotificationById(state, current);
        if (isFileNotification(notification)) {
          prev.resourceIds.push(notification.Resource.Id);
        } else {
          prev.notificationIdsFinal.push(notification.Id);
        }
        return prev;
      },
      { resourceIds: [], notificationIdsFinal: [] }
    );

    dispatch(
      showShareFilesAndMessagesWithChannelModal({
        workspaceId,
        notificationIds: notificationIdsFinal,
        resourceIds,
      })
    );
  };

const showShareFilesAndMessagesWithChannelModal =
  ({ workspaceId, notificationIds, resourceIds }) =>
  (dispatch, getState) => {
    dispatch(
      showModal(modalTypes.SHARE_FILES_AND_MESSAGES_WITH_CHANNEL, {
        notificationIds,
        resourceIds,
        workspaceId,
      })
    );
  };

export function hideShareFilesAndMessagesWithChannelModal() {
  return hideModal(modalTypes.SHARE_FILES_AND_MESSAGES_WITH_CHANNEL);
}

export function hideMigrateChatModal() {
  return hideModal(modalTypes.CHAT_MIGRATION);
}

export const showGuestUpgradeModal = workspaceId =>
  showWorkspaceUpgradeModal(workspaceId, products.guests);

export function hideGuestUpgradeModal() {
  return hideWorkspaceUpgradeModal();
}

export function showConversationFeatureRestrictionModal() {
  return showModal(modalTypes.CONVERSATION_FEATURE_RESTRICTED);
}

export function hideConversationFeatureRestrictionModal() {
  return hideModal(modalTypes.CONVERSATION_FEATURE_RESTRICTED);
}

export function showSaveReminderModal(reminderId) {
  return showModal(modalTypes.SAVE_REMINDER, {
    reminderId,
  });
}

export function hideSaveReminderModal() {
  return hideModal(modalTypes.SAVE_REMINDER);
}

export function showAddReminderModal(
  channelId,
  reminderType,
  targetId,
  target
) {
  return showModal(modalTypes.ADD_REMINDER, {
    channelId,
    reminderType,
    targetId,
    target,
  });
}

export function hideAddReminderModal() {
  return hideModal(modalTypes.ADD_REMINDER);
}

export function showSnoozeReminderModal(reminderId) {
  return showModal(modalTypes.SNOOZE_REMINDER, {
    reminderId,
  });
}

export function hideSnoozeReminderModal() {
  return hideModal(modalTypes.SNOOZE_REMINDER);
}

export function showAddMemebersToFirstWorkSpace(
  workspaceId,
  channelId,
  planCode
) {
  return showModal(modalTypes.ADD_MEMBERS_TO_FIRST_WORKSPACE, {
    workspaceId,
    channelId,
    planCode,
  });
}

export function hideAddMemebersToFirstWorkSpace() {
  return hideModal(modalTypes.ADD_MEMBERS_TO_FIRST_WORKSPACE);
}

export function showAddFirstChannelModal(channelId, planCode) {
  return showModal(modalTypes.ADD_FIRST_CHANNEL_MODEL, {
    channelId,
    planCode,
  });
}

export function hideAddFirstChannelModal() {
  return hideModal(modalTypes.ADD_FIRST_CHANNEL_MODEL);
}

export function showPurchaseWorkspacePlanModal(
  workspaceId,
  channelId,
  planCode
) {
  return showModal(modalTypes.PURCHASE_WORKSPACE_PLAN, {
    workspaceId,
    channelId,
    planCode,
  });
}

export function hidePurchaseWorkspacePlanModal() {
  return hideModal(modalTypes.PURCHASE_WORKSPACE_PLAN);
}

export function showPurchaseWorkspacePlanCompleteModal(
  workspaceId,
  channelId,
  selectedPlanCode
) {
  return showModal(modalTypes.PURCHASE_WORKSPACE_PLAN_COMPLETE, {
    workspaceId,
    channelId,
    selectedPlanCode,
  });
}

export function hidePurchaseWorkspacePlanCompleteModal() {
  return hideModal(modalTypes.PURCHASE_WORKSPACE_PLAN_COMPLETE);
}

export const showMeetingsRestrictedModal = roomId => {
  return showModal(modalTypes.MEETINGS_RESTRICTED, {
    roomId,
  });
};
export function hideMeetingsRestrictedModal() {
  return hideModal(modalTypes.MEETINGS_RESTRICTED);
}

export function showWorkspaceFolderUploadUnavailableModal() {
  return showModal(modalTypes.WORKSPACE_FOLDER_UPLOAD_UNAVAILABLE);
}

export function hideWorkspaceFolderUploadUnavailableModal() {
  return hideModal(modalTypes.WORKSPACE_FOLDER_UPLOAD_UNAVAILABLE);
}

export function showCreateMeetingModal(defaultWorkspaceId, defaultChannelId) {
  return showModal(modalTypes.SELECT_MEETING_USERS, {
    defaultWorkspaceId,
    defaultChannelId,
  });
}

export function hideCreateMeetingModal() {
  return hideModal(modalTypes.SELECT_MEETING_USERS);
}

export function showEducationApplicationModal(workspaceId) {
  return showModal(modalTypes.EDUCATION_APPLICATION, {
    workspaceId,
  });
}

export function hideEducationApplicationModal() {
  return hideModal(modalTypes.EDUCATION_APPLICATION);
}

export function showDesktopAppReviewModal() {
  return showModal(modalTypes.DESKTOP_APP_REVIEW);
}

export function hideDesktopAppReviewModal() {
  return hideModal(modalTypes.DESKTOP_APP_REVIEW);
}

export function showCongratulationsModal() {
  return showModal(modalTypes.CONGRATULATIONS_MODAL);
}

export function hideCongratulationsModal() {
  return hideModal(modalTypes.CONGRATULATIONS_MODAL);
}

export function showRemoveNoteModal(note) {
  return showModal(modalTypes.REMOVE_NOTE_MODAL, { note });
}

export function hideRemoveNoteModal() {
  return hideModal(modalTypes.REMOVE_NOTE_MODAL);
}

export function showRestoreNoteModal(currentNote, noteToRestore, onRestored) {
  return showModal(modalTypes.RESTORE_NOTE_MODAL, {
    currentNote,
    noteToRestore,
    onRestored,
  });
}

export function hideRestoreNoteModal() {
  return hideModal(modalTypes.RESTORE_NOTE_MODAL);
}

export function showNoteHistoryModal(note) {
  return showModal(modalTypes.NOTE_HISTORY_MODAL, {
    note,
  });
}

export function hideNoteHistoryModal() {
  return hideModal(modalTypes.NOTE_HISTORY_MODAL);
}

export function showSuggestionsModal() {
  return showModal(modalTypes.SUGGESTIONS_MODAL);
}

export function hideSuggestionsModal() {
  return hideModal(modalTypes.SUGGESTIONS_MODAL);
}

export function showSpring2024PromitionAcceptedModal(workspaceId) {
  return showModal(modalTypes.SPRING_2024_PROMOTION_ACCEPTED, { workspaceId });
}

export function hideSpring2024PromitionAcceptedModal() {
  return hideModal(modalTypes.SPRING_2024_PROMOTION_ACCEPTED);
}

export function showSpring2024MilestonesModal(workspaceId) {
  return showModal(modalTypes.SPRING_2024_PROMOTION_MILESTONES, {
    workspaceId,
  });
}

export function hideSpring2024MilestonesModal() {
  return hideModal(modalTypes.SPRING_2024_PROMOTION_MILESTONES);
}

export function showDownloadDesktopOfferModal(workspaceId) {
  return showModal(modalTypes.DOWNLOAD_DESKTOP_OFFER, { workspaceId });
}

export function hideDownloadDesktopOfferModal() {
  return hideModal(modalTypes.DOWNLOAD_DESKTOP_OFFER);
}

export function showAppFeedbackOfferModal(workspaceId) {
  return showModal(modalTypes.APP_FEEDBACK_OFFER, { workspaceId });
}

export function hideAppFeedbackOfferModal() {
  return hideModal(modalTypes.APP_FEEDBACK_OFFER);
}

export function showFeedbackOfferAcceptedModal(workspaceId) {
  return showModal(modalTypes.APP_FEEDBACK_OFFER_ACCEPTED, { workspaceId });
}

export function hideFeedbackOfferAcceptedModal() {
  return hideModal(modalTypes.APP_FEEDBACK_OFFER_ACCEPTED);
}

export function showCreateTagModal(tag, color, channelId) {
  return showModal(modalTypes.CREATE_TAG, {
    tag,
    color,
    channelId,
  });
}

export function hideCreateTagModal() {
  return hideModal(modalTypes.CREATE_TAG);
}

export function showManageTagsModal(channelId) {
  return showModal(modalTypes.MANAGE_TAGS, {
    channelId,
  });
}

export function hideManageTagsModal() {
  return hideModal(modalTypes.MANAGE_TAGS);
}

export function showDeleteTagModal(channelId, tag) {
  return showModal(modalTypes.DELETE_TAG, {
    channelId,
    tag,
  });
}

export function hideDeleteTagModal() {
  return hideModal(modalTypes.DELETE_TAG);
}
