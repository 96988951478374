import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { showRemoveNoteModal } from 'store/Modals/actions';

import { useCanDeleteNote } from 'Hooks/data/notes/useNotes';

import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { DeleteIcon } from 'Components/Icons/WeTeamIcon';

function RemoveNoteButton({ note, onClick, intl }) {
  const canDelete = useCanDeleteNote(note);

  const dispatch = useDispatch();

  const onShowDialog = event => {
    event.stopPropagation();
    dispatch(showRemoveNoteModal(note));
  };

  if (!canDelete) {
    return null;
  }

  return <WeTeamIcon onClick={onShowDialog} Icon={DeleteIcon} />;
}

RemoveNoteButton.propTypes = {
  note: PropTypes.object.isRequired,
};

export default RemoveNoteButton;
