import { useState } from 'react';
import PropTypes from 'prop-types';

import ManageParticipantsIcon from 'Components/Icons/Meeting/ManageParticipantsIcon';
import ManageParticipantsModal from './ManageParticipantsModal';

function ManageParticipantsButton({ meeting }) {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {modalVisible && (
        <ManageParticipantsModal
          meeting={meeting}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
      <ManageParticipantsIcon
        onClick={() => {
          setModalVisible(true);
        }}
      />
    </>
  );
}

ManageParticipantsButton.propTypes = {
  meeting: PropTypes.object.isRequired,
};

export default ManageParticipantsButton;
