import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { FormattedMessage } from 'react-intl';
import { getIntegrationsBrowseUrl } from 'pages';

import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { AppsIcon } from 'Components/Icons/WeTeamIcon';

import AppAreaButton from './AppAreaButton';
import { useWorkspace } from 'Hooks/data/workspace/useWorkspace';
import { isAppsEnabled } from 'config/features';

/*
  This buttons appears on routes which contain either the
  channelId or the workspaceId.
  If the workspaceId is not available in the url, we can find
  the channel and use the channel's workspaceId instead.
*/
export function AppsButtons({
  params: { workspaceId, spaceId },
  sidebarOpen,
  router: { push },
}) {
  const workspace = useWorkspace(workspaceId, spaceId);
  const onClick = () => {
    if (workspace.Id) {
      push(getIntegrationsBrowseUrl(workspace.Id));
    }
  };

  if (!isAppsEnabled()) {
    return null;
  }

  return (
    <AppAreaButton
      onClick={onClick}
      icon={<WeTeamIcon Icon={AppsIcon} color="white" link={false} />}
      name={<FormattedMessage id="AppNavigation.Apps" />}
      tooltip={<FormattedMessage id="AppNavigation.Apps.Tooltip" />}
      sidebarOpen={sidebarOpen}
    />
  );
}

AppsButtons.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    spaceId: PropTypes.string,
    workspaceId: PropTypes.string,
  }).isRequired,
};

export default withRouter(AppsButtons);
