import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';

import { saveOne } from 'DataLayer/database/notes';

import { NOTES } from 'DataLayer/endpoints';

export const post = async note => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.post(NOTES, note, config);

  saveOne(data);
  return data;
};
