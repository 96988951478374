import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';

import { remove as removeFromCache } from 'DataLayer/database/notes';

import { getNoteDeleteUrl } from 'DataLayer/endpoints';

export const remove = async ({ id, key }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  removeFromCache(id);

  return await axios.delete(getNoteDeleteUrl(key), config);
};
