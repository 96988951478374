import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { getIsChannelMeetingByRoomId } from 'store/Meeting/selectors';

import ChannelMembersList from 'Components/Meeting/ManageParticipants/ChannelMembersList';
import WorkspaceMembersList from 'Components/Meeting/ManageParticipants/WorkspaceMembersList';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

const ManageParticipantsModal = ({ meeting, onClose }) => {
  const isChannelMeeting = useSelector(state =>
    getIsChannelMeetingByRoomId(state, meeting.roomId)
  );

  return (
    <Modal size="mini" open onClose={onClose}>
      <Header>
        <FormattedMessage id="ManageParticipants.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={onClose} />
        </div>
      </Header>
      <Modal.Content>
        <ErrorBoundary>
          {isChannelMeeting ? (
            <ChannelMembersList meeting={meeting} />
          ) : (
            <WorkspaceMembersList meeting={meeting} />
          )}
        </ErrorBoundary>
      </Modal.Content>
    </Modal>
  );
};

ManageParticipantsModal.propTypes = {
  meeting: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ManageParticipantsModal;
