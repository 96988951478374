import { isNodeEnvDevelopment } from 'config/config';

export const SPACE = 'space';
export const CONVERSATION = 'conversation';

export const getConversationNotificationsUrl = () =>
  `${CONVERSATION}/notifications`;

export const getConversationNotificationsPutUrl = (deliveryInterval, flags) => {
  const flagsArray = Object.keys(flags).filter(key => !!flags[key]);
  const flagsString = [...flagsArray, deliveryInterval].join(',');
  return `${CONVERSATION}/notifications?flags=${flagsString}`;
};
export const getConversationUrl = fields => `${CONVERSATION}?fields=${fields}`;

export const getConversationResendUrl = id => `${CONVERSATION}/${id}/resend`;

export const CONVERSATION_MEMBERS = `${CONVERSATION}/members`;
export const TYPING = 'typing';
export const CHANNEL = 'channel';

export const ICONS = 'icons';
export const ICONS_DEFAULT = `${ICONS}/default`;
export const getIconsUrl = workspaceId => `${ICONS}?workspaceId=${workspaceId}`;
export const getIconsDefaultUrl = workspaceId =>
  `${ICONS_DEFAULT}?workspaceId=${workspaceId}`;

export const WORKSPACE = 'workspace';
export const WORKSPACES = 'workspaces';

export const getWorkspaceFeedbackUrl = id => `workspace/${id}/feedback`;
export const getWorkspaceOffersUrl = id => `workspace/${id}/offers`;
export const getActiveWorkspaceOfferUrl = id => `workspace/${id}/offers/active`;

export const getWorkspaceUnseenUrl = () => `${WORKSPACE}/unseen`;

export const getMembersByWorkspaceIdUrl = workspaceId =>
  `${WORKSPACE}/${workspaceId}/collaborators`;

export const getWorkspaceExportUrl = id => `workspace/${id}/backup`;
export const getWorkspaceExportHistoryUrl = id =>
  `${getWorkspaceExportUrl(id)}/log`;
export const COLLABORATORS = 'collaborators';

export const getWorkspaceCollaboratorsUrl = workspaceId =>
  `${WORKSPACE}/${workspaceId}/${COLLABORATORS}`;

export const getWorkspaceSubscriptionCreditUrl = workspaceId =>
  `workspace/${workspaceId}/subscription/credit`;

export const getWorkspaceSubscriptionBillingInformationUrl = workspaceId =>
  `workspace/${workspaceId}/subscription/billing-information`;

export const getWorkspaceSubscriptionUrl = workspaceId =>
  `workspace/${workspaceId}/subscription/summary`;

export const getWorkspaceSubscriptionCancelUrl = (
  workspaceId,
  subscriptionId
) => `workspace/${workspaceId}/subscription/${subscriptionId}/cancel`;

export const getWorkspaceSubscriptionTerminateUrl = (
  workspaceId,
  subscriptionId
) => `workspace/${workspaceId}/subscription/${subscriptionId}/terminate`;

export const getWorkspaceSubscriptionReactiveUrl = (
  workspaceId,
  subscriptionId
) => `workspace/${workspaceId}/subscription/${subscriptionId}/reactivate`;

export const getInvoiceUrl = (workspaceId, invoiceId) =>
  `workspace/${workspaceId}/subscription/invoice/${invoiceId}`;

export const ALL_COLLABORATORS = `${SPACE}/all/${COLLABORATORS}`;
export const CHAT = `${CHANNEL}/chat`;
export const COMMENTS = 'comments';
export const REPOSITORIES = 'repositories';
export const RESOURCES = 'resources';
export const SEARCH = 'resources/search';
export const ADAPTERS = 'CloudServiceAdapters';
export const ME = 'Me';

export const getMeDeleteRequestUrl = () => `${ME}/delete`;

export const getMeUrl = () => `${ME}?pageToken`;
export const getMeLogoutUrl = () => `${ME}?logout`;
export const getMeDeleteUrl = reason =>
  `${ME}?reason=${encodeURIComponent(reason)}`;

export const getMePutUrl = (
  firstname,
  lastname,
  language,
  timezoneId,
  copyFilesInSingleSpaces
) =>
  `${ME}?firstname=${encodeURIComponent(
    firstname
  )}&lastname=${encodeURIComponent(
    lastname
  )}&language=${language}&timezone=${timezoneId}&copyFilesInSingleSpaces=${copyFilesInSingleSpaces}`;

export const getMePutPasswordUrl = password =>
  `${ME}?password=${encodeURIComponent(password)}`;

export const getMePutPostBillingUrl = activated =>
  `${ME}?postBilling=${activated}`;

export const getMePutLanguageUrl = language => `${ME}?language=${language}`;

export const ME_EMAILS = `${ME}/Emails`;

export const getMeEmailsPutUrl = email =>
  `${ME_EMAILS}?Emails=${encodeURIComponent(email)}`;

export const getMeEmailsDeleteUrl = email =>
  `${ME_EMAILS}?Emails=${encodeURIComponent(email)}`;

export const getMeEmailsPostUrl = email =>
  `${ME_EMAILS}?Emails=${encodeURIComponent(email)}`;

export const SHARE_TO_GROUP = 'ShareToGroup';

export const getShareToGroupBySpaceIdUrl = (spaceId, next, fields) =>
  `${SHARE_TO_GROUP}/${spaceId}?fields=${fields}&next=${next}`;

export const getShareToGroupByIdUrl = (spaceId, linkedItemId, fields) =>
  `${SHARE_TO_GROUP}/${spaceId}?id=${linkedItemId}&fields=${fields}`;

export const getShareToGroupPostUrl = (spaceId, fields) =>
  `${SHARE_TO_GROUP}/${spaceId}?fields=${fields}`;

export const getShareToGroupDeleteUrl = (spaceId, resourceId) =>
  `${SHARE_TO_GROUP}/${spaceId}?resourceId=${resourceId}`;

export const getShareToGroupPutPrivilegeUrl = (
  spaceId,
  resourceId,
  privilege
) =>
  `${SHARE_TO_GROUP}/${spaceId}?resourceId=${resourceId}&privilege=${privilege}`;

export const getShareToGroupPutNameUrl = (spaceId, resourceId, name) =>
  `${SHARE_TO_GROUP}/${spaceId}?resourceId=${resourceId}&name=${encodeURIComponent(
    name
  )}`;

export const getShareToGroupPutStarredUrl = (spaceId, resourceId, starred) =>
  `${SHARE_TO_GROUP}/${spaceId}?resourceId=${resourceId}&Starred=${starred}`;

export const getShareToGroupSearchUrl = (
  workspaceId,
  query,
  channelIds,
  memberEmails,
  startDate,
  endDate
) => {
  const qs = [];
  if (workspaceId) {
    qs.push(`WorkspaceId=${workspaceId}`);
  }

  if (query) {
    qs.push(`Query=${query}`);
  }

  if (channelIds && channelIds.length) {
    qs.push(`ChannelIds=${channelIds.join(',')}`);
  }

  if (memberEmails && memberEmails.length) {
    qs.push(`MemberEmails=${encodeURIComponent(memberEmails.join(','))}`);
  }

  if (startDate) {
    qs.push(`StartDate=${startDate}`);
  }

  if (endDate) {
    qs.push(`EndDate=${endDate}`);
  }
  return `${SHARE_TO_GROUP}/search?${qs.join('&')}`;
};

export const SHARE = 'Share';
export const SHARE_EMAIL = 'Share/email';

export const AUTHENTICATION = 'Authentication';

export const getAuthenticationPostUrl = token => {
  return token ? `${AUTHENTICATION}/${token}` : AUTHENTICATION;
};

export const getNativeApplePostUrl = () => {
  return 'apple/callback/native';
};

export const RESET_PASSWORD = 'reset-password';
export const getResetPasswordUrl = token =>
  `${RESET_PASSWORD}/${encodeURIComponent(token)}`;

export const FORGOT_PASSWORD = 'forgot-password';

export const MEDIA_INFO = 'MediaInfo';
export const THUMBNAIL = 'MediaThumbnail';
export const PUBLIC_MEDIA_INFO = 'linkedMediaInfo';
export const SPACE_NOTIFICATIONS = 'channel/notifications';
export const TASK = 'Task';
export const MY_NOTIFICATIONS = 'MyNotifications';
export const getMyNotificationsPostUrl = spaceId =>
  `${MY_NOTIFICATIONS}?spaceId=${spaceId}`;

export const getMyNotificationsStatsUrl = () =>
  `${MY_NOTIFICATIONS}/stats?includeChatSpaces`;

export const getMyNotificationForActivitiesUrl = beforeNotificationId => {
  const qs = [];
  qs.push('Limit=15');
  qs.push('OmitOwnNotifications');
  qs.push('CorrectChatNames');

  if (beforeNotificationId) {
    qs.push(`before=${encodeURIComponent(beforeNotificationId)}`);
  }
  return `${MY_NOTIFICATIONS}?${qs.join('&')}`;
};

export const WORKSPACE_SUBSCRIPTION = 'subscription/workspace';
export const TRANSFER_SUBSCRIPTION = 'subscription/transfer';
export const TRANSFER_SUBSCRIPTION_CREDIT = 'subscription/transfer/credit';
export const TRANSFER_SUBSCRIPTION_PRICE = 'subscription/transfer/price';

export const PUBLIC_RESOURCES = 'linkedResources'; // use for public access to a user's resources
export const USER_GROUPS = 'userSwitch';
export const TEMPLATES = 'templates';
export const getTemplatesCategoriesUrl = () =>
  `${TEMPLATES}?type=categories&size=1000&from=0`;
export const getTemplatesAppsUrl = () =>
  `${TEMPLATES}?type=apps&size=1000&from=0`;
export const getTemplatesUrl = () =>
  `${TEMPLATES}?type=templates&size=1000&from=0`;

export const ZAPS = 'zaps';
export const WORKGROUPS = 'workgroups';
export const MIGRATE = 'migrate';

export const ZAPIER_DISCONNECT = 'zapierdisconnect';
export const DESKTOP_SETTINGS = 'desktopSettings';

export const getDesktopSettingsUrl = (appKey, email, token) =>
  `${DESKTOP_SETTINGS}?appKey=${encodeURIComponent(
    appKey
  )}&email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`;

export const DEVICE_INFO = 'deviceInfo';

export const STARRED = 'stars';
export const REMINDERS = 'reminders';
export const USER_STATUS = 'userStatus';
export const getUserStatusUrl = (email, token) =>
  `${USER_STATUS}?email=${encodeURIComponent(email)}&token=${token}`;

export const NEW_WEB_UI_PREFERENCES = 'webUIPreferences';
export const WEB_UI_PREFERENCES = 'WebUIPreferences';
export const USER_PRESENCE = 'userPresence';

export const MEETINGS = 'meetings';

export const getMeetingUsersPostUrl = meetingId =>
  `meetings/${meetingId}/users`;

export const getMeetingUsersDeleteUrl = (meetingId, userId) =>
  `meetings/${meetingId}/users/${userId}`;

export const MEETINGS_INSTANT = 'meetings/instant';
export const MEETINGS_SCHEDULED = 'meetings/scheduled';

export const getStartScheduledMeetingUrl = (meetingId, token) =>
  `meetings/scheduled/${meetingId}/start?token=${token}`;

export const getInitScheduledMeetingUrl = (meetingId, token) =>
  `meetings/scheduled/${meetingId}/init?token=${token}`;

export const USERS = 'users';

export const EDUCATION = 'education';

export const getUsersPostUrl = token => {
  return token ? `${USERS}/${token}` : USERS;
};

export const USERS_VERIFY = `${USERS}/verify`;
export const USERS_VERIFY_RESEND = `${USERS_VERIFY}/resend`;
export const USERS_WORKSPACE = `${USERS}/workspace`;
export const USERS_WORKSPACE_SUGGESTION = `${USERS_WORKSPACE}/suggestion`;
export const getWorkspaceNameSuggestionUrl = token =>
  `${USERS_WORKSPACE}/suggestion?token=${token}`;

export const NOTES = 'notes';
export const getNoteByChannelIdUrl = channelId =>
  channelId ? `channel/${channelId}/${NOTES}` : NOTES;
export const getNoteDeleteUrl = key => `${NOTES}/${key}`;
export const getNoteUrl = key => `${NOTES}/${key}`;
export const getNotePutUrl = key => `${NOTES}/${key}`;
export const getNoteLockUrl = key => `${NOTES}/${key}/lock`;
export const getNoteUnlockUrl = key => `${NOTES}/${key}/unlock`;
export const getNoteHistoryUrl = key => `${NOTES}/${key}/history`;

export const getUnverifiedUserRequestAllowedConfig = () => ({
  unverifiedUserRequestAllowed: true,
});

export const isUnverifiedUserRequestAllowed = config => {
  if (!config.unverifiedUserRequestAllowed) {
    if (isNodeEnvDevelopment()) {
      console.debug(
        'This request has been cancelled because the user is not verified and the request config does not have an `unverifiedUserRequestAllowed` flag',
        config
      );
    }
  }
  return config.unverifiedUserRequestAllowed;
};

export const ANALYTICS = 'analytics';

export const getResourceTagsByChannelIdUrl = channelId =>
  `channels/${channelId}/resources/tags`;

export const getTagsByChannelIdUrl = channelId => `channels/${channelId}/tags`;

export const getPutTagUrl = (channelId, tagId) =>
  `channels/${channelId}/tags/${tagId}`;

export const getDeleteTagUrl = (channelId, tagId) =>
  `channels/${channelId}/tags/${tagId}`;

export const getTagsByChannelIdPutUrl = (channelId, resourceId) =>
  `channels/${channelId}/resources/${resourceId}/tags`;
