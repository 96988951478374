import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import * as Sentry from '@sentry/react';
import { Link } from 'react-router';
import { getLinkedItemsLink } from 'pages';

import { truncateAround } from 'Helpers/Strings/truncateAround';
import Highlighter from 'react-highlight-words';
import ResourcePreviewLink from './NotificationTypeComponents/ResourcePreviewLink';
import ResourceComment from './NotificationTypeComponents/ResourceComment';
import SpaceTask from './NotificationTypeComponents/SpaceTask';
import Reminder from './NotificationTypeComponents/Reminder';
import UserMessage from 'Components/Chat/UserMessage';
import notificationType, {
  MEETING_NOTIFICATION,
} from 'Constants/notificationType';
import ResourceLinked from './NotificationTypeComponents/ResourceLinked';
import Meeting from './NotificationTypeComponents/Meeting/Meeting';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import Note from 'Components/Notification/NotificationTypeComponents/Note/Note';

const getChannelResourceLinked = (notification, intl, plain, showPreview) => {
  if (plain || notification.ResourceRemoved) {
    let text = intl.formatMessage(
      {
        id: 'SpaceChatItems.WorkspaceResourceLinked',
      },
      {
        name: notification.Resource?.Name,
      }
    );
    if (notification.ResourceRemoved) {
      const removedText = intl.formatMessage({
        id: 'SpaceChatItems.WorkspaceResourceLinked.Removed',
      });
      text = `${text} ${removedText}`;
    }
    return text;
  }
  const text = (
    <FormattedMessage
      id="SpaceChatItems.WorkspaceResourceLinked"
      values={{
        name: (
          <ResourcePreviewLink
            resource={notification.Resource}
            spaceId={notification.Workspace?.Id}
            spaceType={notification.Workspace?.SpaceType}
          />
        ),
      }}
    />
  );
  return (
    <ResourceLinked
      resourceId={notification.Resource?.Id}
      notification={notification}
      text={text}
      intl={intl}
      showPreview={showPreview}
      plain={plain}
    />
  );
};

const getChannelLinkRenamed = (notification, intl, plain) => {
  if (plain) {
    return intl.formatMessage(
      {
        id: 'SpaceChatItems.WorkspaceLinkRenamed',
      },
      {
        name: notification.Resource?.Name,
      }
    );
  }
  return (
    <FormattedMessage
      id="SpaceChatItems.WorkspaceLinkRenamed"
      values={{
        name: (
          <ResourcePreviewLink
            resource={notification.Resource}
            spaceId={notification.Workspace?.Id}
            spaceType={notification.Workspace?.SpaceType}
          />
        ),
      }}
    />
  );
};

const getChannelResourceUnlinked = (notification, intl, plain) => {
  return intl.formatMessage(
    {
      id: 'SpaceChatItems.WorkspaceResourceUnlinked',
    },
    {
      name: notification.Resource?.Name,
    }
  );
};

const getChannelNewResource = (notification, intl, plain, showPreview) => {
  const parentName = notification.Resource?.ParentName || 'folder';
  const resourceName = notification.Resource?.Name;
  if (plain || notification.ResourceRemoved) {
    let text = intl.formatMessage(
      {
        id: 'SpaceChatItems.WorkspaceNewResource',
      },
      {
        name: resourceName,
        parent: parentName,
      }
    );
    if (notification.ResourceRemoved) {
      const removedText = intl.formatMessage({
        id: 'SpaceChatItems.WorkspaceNewResource.Removed',
      });
      text = `${text} ${removedText}`;
    }
    return text;
  }
  const workspaceId = notification.Workspace?.Id;
  const workspaceType = notification.Workspace?.SpaceType;

  const text = (
    <FormattedMessage
      id="SpaceChatItems.WorkspaceNewResource"
      values={{
        name: (
          <ResourcePreviewLink
            resource={notification.Resource}
            spaceId={workspaceId}
            spaceType={workspaceType}
          />
        ),
        parent: (
          <Link
            to={getLinkedItemsLink(
              workspaceId,
              workspaceType,
              notification.Resource?.ParentId
            )}
          >
            {parentName}
          </Link>
        ),
      }}
    />
  );
  return (
    <ResourceLinked
      resourceId={notification.Resource?.Id}
      notification={notification}
      text={text}
      intl={intl}
      showPreview={showPreview}
      plain={plain}
    />
  );
};

const getChannelResourceWritabilityChanged = (notification, intl, plain) => {
  const key = notification.Resource?.Writable
    ? 'SpaceChatItems.GWorkspaceResourceUnlocked'
    : 'SpaceChatItems.GWorkspaceResourceLocked';
  if (plain) {
    return intl.formatMessage(
      {
        id: key,
      },
      {
        name: notification.Resource?.Name,
      }
    );
  }
  return (
    <FormattedMessage
      id={key}
      values={{
        name: (
          <ResourcePreviewLink
            resource={notification.Resource}
            spaceId={notification.Workspace?.Id}
            spaceType={notification.Workspace?.SpaceType}
          />
        ),
      }}
    />
  );
};

const getChannelCollaboratorRemoved = (notification, intl, plain) => {
  return intl.formatMessage(
    {
      id: 'SpaceChatItems.GWorkspaceCollaboratorRemoved',
    },
    {
      name: notification.Collaborator?.Name,
    }
  );
};

const getWorkspaceCollaboratorApproved = (notification, intl, plain) => {
  return intl.formatMessage(
    {
      id: 'SpaceChatItems.GWorkspaceCollaboratorApproved',
    },
    {
      name: notification.Collaborator?.Name,
    }
  );
};

const getWorkspaceCollaboratorRejected = (notification, intl, plain) => {
  return intl.formatMessage(
    {
      id: 'SpaceChatItems.GWorkspaceCollaboratorRejected',
    },
    {
      name: notification.Collaborator?.Name,
    }
  );
};

const getChannelCreated = (notification, intl, plain) => {
  const isSingleSpace = notification.Workspace?.SpaceType === 'CHAT';
  const formattedMessageId = isSingleSpace
    ? 'SpaceChatItems.SingleSpace.GWorkspaceCreated'
    : 'SpaceChatItems.TeamSpace.GWorkspaceCreated';
  return intl.formatMessage({
    id: formattedMessageId,
  });
};

const getChannelRenamed = (notification, intl, plain) => {
  return intl.formatMessage(
    {
      id: 'SpaceChatItems.TeamSpace.GWorkspaceRenamed',
    },
    {
      oldName: notification.Workspace?.OldName,
    }
  );
};

const getResourceComment = (notification, intl, plain) => {
  if (plain) {
    return intl.formatMessage(
      {
        id: 'SpaceChatItems.CommentedOnResourceRaw',
      },
      {
        item: notification.Resource?.Name,
        comment: notification.Comment?.Text,
        br: null,
      }
    );
  }
  if (notification.Resource && notification.Comment) {
    return (
      <ResourceComment
        resource={notification.Resource}
        comment={notification.Comment}
        spaceId={notification.Workspace?.Id}
      />
    );
  }
  return '';
};

const getChannelResourceDownloaded = (notification, intl, plain) => {
  if (plain) {
    return intl.formatMessage(
      {
        id: 'SpaceChatItems.ChannelResourceDownloaded',
      },
      {
        name: notification.Resource?.Name,
      }
    );
  }
  return (
    <FormattedMessage
      id="SpaceChatItems.ChannelResourceDownloaded"
      values={{
        name: (
          <ResourcePreviewLink
            resource={notification.Resource}
            spaceId={notification.Workspace?.Id}
            spaceType={notification.Workspace?.SpaceType}
          />
        ),
      }}
    />
  );
};

const getWorkspaceReward = (notification, intl, plain) => {
  return intl.formatMessage(
    {
      id: 'SpaceChatItems.GWorkspaceReward',
    },
    {
      name: notification.CreatedBy?.FirstName,
      collaborator: notification.Collaborator?.Name,
      bandwidth: notification.Bandwidth ?? '250 MB',
    }
  );
};

const getShareTaskEvent = (notification, intl, plain) => {
  if (plain) {
    return '';
  }
  return (
    <SpaceTask task={notification.Task} spaceId={notification.Workspace?.Id} />
  );
};

const getReminderFired = (notification, intl, plain) => {
  const note = notification.Reminder?.Note;
  if (plain) {
    return note ? `Reminder: ${note}` : 'Reminder';
  }
  return (
    <Reminder
      notification={notification}
      channelId={notification.Workspace?.Id}
    />
  );
};

const getMeeting = (notification, intl, plain) => {
  return (
    <ErrorBoundary showFallback={false}>
      <Meeting activeMeeting={notification.Meeting} />
    </ErrorBoundary>
  );
};

const getNote = (notification, intl, plain) => {
  return (
    <ErrorBoundary showFallback={false}>
      <Note note={notification.Note} />
    </ErrorBoundary>
  );
};

const getChannelComment = (notification, intl, plain) => {
  if (plain) {
    return notification.Comment?.Text;
  }
  return <UserMessage message={notification.Comment?.Text} />;
};

const getChannelCollaboratorAddedText = (notification, intl, plain) => {
  const isSingleSpace = notification.Workspace?.SpaceType === 'CHAT';
  let formattedMessageId;

  if (isSingleSpace) {
    formattedMessageId =
      'SpaceChatItems.SingleSpace.GWorkspaceCollaboratorInvited';
  } else {
    formattedMessageId =
      'SpaceChatItems.TeamSpace.GWorkspaceCollaboratorInvited';
  }

  return intl.formatMessage(
    {
      id: formattedMessageId,
    },
    {
      name:
        notification.Collaborator?.Name ||
        notification.Collaborator?.Email ||
        notification.User,
    }
  );
};

const getChannelCollaboratorStatusChangedText = (notification, intl, plain) => {
  const collaboratorNewStatus = notification.Collaborator?.NewStatus;
  const isSingleSpace = notification.Workspace?.SpaceType === 'CHAT';
  let formattedMessageId;

  if (collaboratorNewStatus === 'INVITED') {
    if (isSingleSpace) {
      formattedMessageId =
        'SpaceChatItems.SingleSpace.GWorkspaceCollaboratorInvited';
    } else {
      formattedMessageId =
        'SpaceChatItems.TeamSpace.GWorkspaceCollaboratorInvited';
    }
  } else if (collaboratorNewStatus === 'JOINED') {
    if (isSingleSpace) {
      formattedMessageId =
        'SpaceChatItems.SingleSpace.GWorkspaceCollaboratorJoined';
    } else {
      formattedMessageId =
        'SpaceChatItems.TeamSpace.GWorkspaceCollaboratorJoined';
    }
  } else if (collaboratorNewStatus === 'LEFT') {
    if (isSingleSpace) {
      formattedMessageId =
        'SpaceChatItems.SingleSpace.GWorkspaceCollaboratorLeft';
    } else {
      formattedMessageId =
        'SpaceChatItems.TeamSpace.GWorkspaceCollaboratorLeft';
    }
  }

  return intl.formatMessage(
    {
      id: formattedMessageId,
    },
    {
      name: notification.Collaborator?.Name || notification.Collaborator?.Email,
    }
  );
};

const textByType = {
  [notificationType.ChannelComment]: getChannelComment,
  [notificationType.SpaceComment_DEPRECATED]: getChannelComment,
  [notificationType.ChannelResourceLinked]: getChannelResourceLinked,
  [notificationType.ChannelLinkRenamed]: getChannelLinkRenamed,
  [notificationType.ChannelResourceUnlinked]: getChannelResourceUnlinked,
  [notificationType.ChannelNewResource]: getChannelNewResource,
  [notificationType.WorkspaceNewResource_DEPRECATED]: getChannelNewResource,
  [notificationType.ChannelResourceWritabilityChanged]:
    getChannelResourceWritabilityChanged,
  [notificationType.GWorkspaceResourceWritabilityChanged_DEPRECATED]:
    getChannelResourceWritabilityChanged,
  [notificationType.ChannelCollaboratorStatusChanged]:
    getChannelCollaboratorStatusChangedText,
  [notificationType.GWorkspaceCollaboratorStatusChanged_DEPRECATED]:
    getChannelCollaboratorStatusChangedText,
  [notificationType.ChannelCollaboratorRemoved]: getChannelCollaboratorRemoved,
  [notificationType.GWorkspaceCollaboratorRemoved_DEPRECATED]:
    getChannelCollaboratorRemoved,
  [notificationType.GWorkspaceCollaboratorApproved]:
    getWorkspaceCollaboratorApproved,
  [notificationType.GWorkspaceCollaboratorRejected]:
    getWorkspaceCollaboratorRejected,
  [notificationType.ChannelCollaboratorAdded]: getChannelCollaboratorAddedText,
  [notificationType.GWorkspaceCollaboratorAdded_DEPRECATED]:
    getChannelCollaboratorAddedText,
  [notificationType.ChannelCreated]: getChannelCreated,
  [notificationType.GWorkspaceCreated_DEPRECATED]: getChannelCreated,
  [notificationType.ChannelRenamed]: getChannelRenamed,
  [notificationType.GWorkspaceRenamed_DEPRECATED]: getChannelRenamed,
  [notificationType.ResourceComment]: getResourceComment,
  [notificationType.ChannelResourceDownloaded]: getChannelResourceDownloaded,
  [notificationType.GWorkspaceReward]: getWorkspaceReward,
  [notificationType.ShareTaskEvent]: getShareTaskEvent,
  [notificationType.ReminderFired]: getReminderFired,
  [MEETING_NOTIFICATION]: getMeeting,
  [notificationType.Note]: getNote,
};

const getTextByType = (notification, intl, plain, showPreview) => {
  try {
    return textByType[notification.Type](
      notification,
      intl,
      plain,
      showPreview
    );
  } catch (e) {
    Sentry.captureException(e);
    return '';
  }
};

const isSystemMessage = type => {
  return !(
    type === notificationType.ResourceComment ||
    type === notificationType.ReminderFired ||
    type === notificationType.ChannelComment ||
    type === notificationType.SpaceComment_DEPRECATED
  );
};

function NotificationText({
  notification,
  intl,
  truncate,
  highlight,
  showPreview,
}) {
  const text = getTextByType(notification, intl, highlight, showPreview); // for the highlighter to work, it requires plain text

  if (highlight) {
    return (
      <Highlighter
        highlightClassName="highlight-text-color"
        className="wrappable-text"
        searchWords={[highlight]}
        autoEscape
        textToHighlight={truncate ? truncateAround(text, highlight, 300) : text}
      />
    );
  }

  if (isSystemMessage(notification.Type)) {
    return <span data-testid="system-text">{text}</span>;
  }

  return <span data-testid="normal-text">{text}</span>;
}

NotificationText.propTypes = {
  notification: PropTypes.object.isRequired,
  intl: intlShape,
  truncate: PropTypes.bool,
  highlight: PropTypes.string,
  showPreview: PropTypes.bool,
};

export default NotificationText;
