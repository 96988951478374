// import {filesize as fileSize} from 'filesize';

function single(repository) {
  const repo = Object.assign({}, repository);

  /*
  if (repo.Name) {
    repo.NameLowerCase = repo.Name.toLowerCase();
  } else {
    //repo.NameLowerCase = '';
  }
*/

  if (
    Object.prototype.hasOwnProperty.call(repo, 'Quota') &&
    repo.Quota.Used > -1
  ) {
    const remaining = repo.Quota.Total - repo.Quota.Used;
    const totalSpace = repo.Quota.Total;
    const usedSpace = repo.Quota.Used;

    /*
    repo.Quota.RemainingFormatted = filesize(remaining, { round: 0 });
    repo.Quota.TotalSpaceFormatted = filesize(totalSpace, { round: 0 });
    repo.Quota.UsedSpaceFormatted = filesize(usedSpace, { round: 0 });
    */

    repo.Quota.Remaining = remaining;
    repo.Quota.TotalSpace = totalSpace;
    repo.Quota.UsedSpace = usedSpace;
  }

  return repo;
}

function all(repositories) {
  if (!repositories) {
    return [];
  }

  return repositories.map(repository => single(repository));
}

export default { all, single };
