import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { saveOne } from 'DataLayer/database/notes';
import {
  getNoteLockUrl,
  getNotePutUrl,
  getNoteUnlockUrl,
} from 'DataLayer/endpoints';

export const put = async ({ key, note, scope }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.put(getNotePutUrl(key), { note, scope }, config);

  saveOne(data);
  return data;
};

export const lock = async ({ key }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.put(getNoteLockUrl(key), null, config);

  return data;
};

export const unlock = async ({ key }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.put(getNoteUnlockUrl(key), null, config);

  return data;
};
