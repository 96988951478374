import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getMembersBySpaceId } from 'store/Spaces/Collaborators/selectors';

import CollaboratorAvatar from 'Components/Collaborators/CollaboratorAvatar';

function NoteAvatar({ channelId, userId }) {
  const channelMembers = useSelector(state =>
    getMembersBySpaceId(state, channelId)
  );

  const user = channelMembers.find(m => m.UserId === userId);
  if (!user) {
    return null;
  }
  return (
    <CollaboratorAvatar
      collaborator={user}
      width={30}
      height={30}
      showStatus
      canClick={false}
    />
  );
}

NoteAvatar.propTypes = {
  note: PropTypes.object.isRequired,
};

export default NoteAvatar;
