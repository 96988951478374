import { useMutation, useQuery, useQueryClient } from 'react-query';
import orderBy from 'lodash/orderBy';

import { fetchNotes, getByKey, getNotesHistory } from 'DataLayer/Notes/get';
import { post } from 'DataLayer/Notes/post';
import { lock, put, unlock } from 'DataLayer/Notes/put';
import { remove } from 'DataLayer/Notes/remove';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getNoteData } from 'store/Connectivity';
import { getUserGuid } from 'store/User/selectors';
import { noteScope } from 'DataLayer/Notes/scope';

const ALL_NOTES = 'ALL';
const NOTES = 'NOTES';
const HISTORY = 'NOTES/HISTORY';

const getHistoryKey = noteKey => [HISTORY, noteKey];

export const useCanEditNote = note => {
  const userId = useSelector(getUserGuid);
  return (
    userId === note.ownerId || // a user can always edit their own note
    note.scope === noteScope.PUBLIC
  ); // all users can edit a public note
};

export const useCanDeleteNote = note => {
  const userId = useSelector(getUserGuid);
  return userId === note.ownerId;
};

export const useNotes = () => useQuery([NOTES, ALL_NOTES], () => fetchNotes());

export const useNoteByKey = noteKey =>
  useQuery([NOTES, noteKey], () => getByKey(noteKey), { enabled: false });

export const useNotesByChannelId = channelId =>
  useQuery([NOTES, channelId || ALL_NOTES], () => fetchNotes(channelId));

export const useNotesHistory = key => {
  const noteData = useSelector(getNoteData);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (noteData.key === key) {
      queryClient.invalidateQueries(getHistoryKey(noteData.key));
    }
  }, [key, queryClient, noteData]);

  return useQuery(getHistoryKey(key), () => getNotesHistory(key), {
    select: notes => {
      return orderBy(notes, note => note.createdAt, 'desc');
    },
  });
};

export const useLockNote = channelId => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: lock,
    onSuccess: () => {
      invalidateQueries(queryClient, channelId);
    },
  });
};

export const useUnlockNote = channelId => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unlock,
    onSuccess: () => {
      invalidateQueries(queryClient, channelId);
    },
  });
};

export const useUpdateNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: note => {
      if (note.key) {
        return put(note);
      }
      return post(note);
    },
    onSuccess: async newNote => {
      await invalidateQueries(queryClient, newNote.channelId);
    },
  });
};

export const useRemoveNote = channelId => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: remove,
    onSuccess: async () => {
      await invalidateQueries(queryClient, channelId);
    },
  });
};

const invalidateQueries = async (queryClient, channelId) => {
  try {
    return queryClient.invalidateQueries([NOTES, channelId || ALL_NOTES]);
  } catch (e) {
    return Promise.resolve();
  }
};

export const useWatchChannelNotesChangesFromWebSocket = channelId => {
  const queryClient = useQueryClient();

  const noteData = useSelector(getNoteData);

  useEffect(() => {
    if (noteData.channelId === channelId) {
      invalidateQueries(queryClient, channelId);
    }
  }, [channelId, queryClient, noteData]);
};
