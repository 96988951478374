import { filesize } from 'filesize';
import isEncrypted from './Helpers/isEncrypted';
import isFolder from './Helpers/isFolder';
import setItemPermission from './Helpers/setItemPermission';

function processFolder(item) {
  const itemUpdated = item;
  itemUpdated.Folder = true;

  return itemUpdated;
}

function processFile(item) {
  const itemUpdated = Object.assign({}, item);
  itemUpdated.Folder = false;
  if (itemUpdated.Size >= 0) {
    itemUpdated.SizeFormatted = filesize(itemUpdated.Size, { round: 0 });
  } else {
    itemUpdated.SizeFormatted = '';
  }

  if (itemUpdated.MimeType) {
    itemUpdated.isImage = itemUpdated.MimeType.indexOf('image') > -1;
    itemUpdated.isVideo = itemUpdated.MimeType.indexOf('video') > -1;
    itemUpdated.isAudio = itemUpdated.MimeType.indexOf('audio') > -1;
  }
  itemUpdated.isEncrypted = isEncrypted(itemUpdated.Type);

  return itemUpdated;
}

function single(item) {
  const itemUpdated = Object.assign({}, item);

  if (itemUpdated.Name) {
    itemUpdated.NameLowerCase = itemUpdated.Name.toLowerCase();
  }

  setItemPermission(itemUpdated);

  try {
    itemUpdated.TypeDisplay = itemUpdated.Type.toLowerCase();
  } catch (exception) {
    itemUpdated.TypeDisplay = '';
  }

  if (isFolder(itemUpdated.Type)) {
    return processFolder(itemUpdated);
  }
  return processFile(itemUpdated);
}

function all(items) {
  if (!items) {
    return [];
  }

  return items.map(item => single(item));
}

export default {
  single,
  all,
};
