import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Popup, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import {
  getIsMeetingHangUpRequested,
  isMeetingJoined,
} from 'store/Meeting/selectors';

import { meetingHangUpRequested, showMeeting } from '../../store/Meeting';
import MeetingIcon from 'Components/Meeting/MeetingIcon';
import MeetingIconStatus from 'Components/Meeting/MeetingIconStatus';
import ManageParticipantsButton from 'Components/Meeting/ManageParticipants/ManageParticipantsButton';
import JoinMeetingButton from 'Components/Meeting/JoinMeetingButton';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import HangUp from '../Icons/Meeting/HangUp';

import styles from './MeetingToolbar.module.scss';

export function MeetingToolbar({
  meeting,
  isMeetingHangUpRequested,
  isMeetingJoined,
  onShowHideMeetingClick,
  onEndMeetingClick,
}) {
  // tell the meeting window to end the meeting
  const onEndClick = () => {
    onEndMeetingClick();
  };

  const onShowMeetingClick = () => {
    onShowHideMeetingClick();
  };

  return (
    <div className={styles.meetingToolbar}>
      {!isMeetingJoined ? (
        <div className={styles.joinButton}>
          <JoinMeetingButton roomId={meeting.roomId} />
        </div>
      ) : (
        <>
          <Popup
            inverted
            position="bottom center"
            trigger={
              <span className={classNames(styles.icon)}>
                <span
                  data-testid="meeting-status-icon"
                  className={styles.meetingStatusIcon}
                >
                  <MeetingIcon />
                </span>
              </span>
            }
            content={<MeetingIconStatus />}
            size="mini"
          />
          <Popup
            inverted
            position="bottom center"
            trigger={
              <span
                role="button"
                className={classNames(styles.icon, styles.button, styles.red)}
                data-testid="hang-up-meeting-button"
                onClick={onEndClick}
              >
                <HangUp className={styles.hangUpIcon} width={24} height={24} />
              </span>
            }
            content={<FormattedMessage id="MeetingToolbar.EndMeeting" />}
            size="mini"
          />
          <Popup
            inverted
            position="bottom center"
            trigger={
              <span
                role="button"
                className={classNames(styles.icon, styles.button)}
                onClick={onShowMeetingClick}
                data-testid="show-hide-meeting-button"
              >
                <Icon
                  data-testid="show-hide-meeting-icon"
                  className={styles.showHideMeetingIcon}
                  name="eye"
                />
              </span>
            }
            content={<FormattedMessage id="MeetingToolbar.ShowMeeting" />}
            size="mini"
          />
        </>
      )}
      {meeting.participantUserIds.length > 0 && (
        <Popup
          inverted
          position="bottom center"
          trigger={
            <span
              role="button"
              className={classNames(styles.icon, styles.button, styles.green)}
              data-testid="meeting-participants-button"
            >
              <ErrorBoundary>
                <ManageParticipantsButton meeting={meeting} />
              </ErrorBoundary>
            </span>
          }
          content={<FormattedMessage id="MeetingToolbar.ManageParticipants" />}
          size="mini"
        />
      )}
    </div>
  );
}

MeetingToolbar.propTypes = {
  meeting: PropTypes.object.isRequired,
  isMeetingHangUpRequested: PropTypes.bool,
  isMeetingJoined: PropTypes.bool,
  onShowHideMeetingClick: PropTypes.func.isRequired,
  onEndMeetingClick: PropTypes.func.isRequired,
};

export default connect(
  (state, props) => ({
    isMeetingJoined: isMeetingJoined(state, props.meeting.roomId),
    isMeetingHangUpRequested: getIsMeetingHangUpRequested(state),
  }),
  {
    onShowHideMeetingClick: showMeeting,
    onEndMeetingClick: meetingHangUpRequested,
  }
)(MeetingToolbar);
