/*
  https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
  Map Jitsi events to Otixo events
*/

import {
  createWebSocketMessage,
  websocketMessageContext,
} from 'DataLayer/WebSocket';

export const ACTIVE_MEETING_HEARTBEAT_INTERVAL = 5000;

export const MEETING_EVENTS = {
  meetingParticipantJoined: 'MeetingParticipantJoined',
  meetingParticipantLeft: 'MeetingParticipantLeft',
  meetingInvitationAccepted: 'MeetingInvitationAccepted',
  meetingInvitationRejected: 'MeetingInvitationRejected',
  meetingInvitationReceived: 'MeetingInvitationReceived',
  activeMeeting: 'ActiveMeeting',
};

export const createMeetingParticipantJoinedMessage = (userId, roomId) =>
  createWebSocketMessage(
    MEETING_EVENTS.meetingParticipantJoined,
    websocketMessageContext.meeting,
    {
      userId,
      roomId,
    }
  );

export const createMeetingParticipantLeftMessage = (userId, roomId) =>
  createWebSocketMessage(
    MEETING_EVENTS.meetingParticipantLeft,
    websocketMessageContext.meeting,
    {
      userId,
      roomId,
    }
  );

export const createMeetingInvitationAcceptedMessage = (userId, roomId) =>
  createWebSocketMessage(
    MEETING_EVENTS.meetingInvitationAccepted,
    websocketMessageContext.meeting,
    {
      userId,
      roomId,
    }
  );

export const createMeetingInvitationRejectedMessage = (userId, roomId) =>
  createWebSocketMessage(
    MEETING_EVENTS.meetingInvitationRejected,
    websocketMessageContext.meeting,
    {
      userId,
      roomId,
    }
  );

export const createMeetingInvitationReceivedMessage = (userId, roomId) =>
  createWebSocketMessage(
    MEETING_EVENTS.meetingInvitationRejected,
    websocketMessageContext.meeting,
    {
      userId,
      roomId,
    }
  );

export const createActiveMeetingMessage = (userId, roomId) =>
  createWebSocketMessage(
    MEETING_EVENTS.activeMeeting,
    websocketMessageContext.meeting,
    {
      userId,
      roomId,
    }
  );
