import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import { convertMarkdownToDelta } from 'Components/Notes/helpers';

import styles from './NoteText.module.css';

function NoteText({ note, truncated }) {
  const finalNote = convertMarkdownToDelta(note);

  return (
    <p
      className={classNames({
        [styles.truncated]: truncated,
      })}
    >
      <ReactQuill theme="bubble" value={finalNote} readOnly />
    </p>
  );
}

NoteText.propTypes = {
  note: PropTypes.string.isRequired,
};

export default props => (
  <ErrorBoundary showFallback={false}>
    <NoteText {...props} />
  </ErrorBoundary>
);
