import { ENVIRONMENTS, getOtixoEnvironment } from 'config/config';
import {
  isWhitelistedEnvironment,
  isWhitelistedWorkspace,
  featureConfig,
  FEATURES,
} from './features.config';

export const isFeatureEnabled = (feature, workspaceId) => {
  try {
    const config = featureConfig[feature];

    const currentEnvironment = getOtixoEnvironment();

    // if the environment is whitelisted, always enable
    if (
      isWhitelistedEnvironment(
        config.whitelistedEnvironments,
        currentEnvironment
      )
    ) {
      return true;
    }

    // if the environment is not whitelisted, check the workspaces whitelist
    if (
      workspaceId &&
      currentEnvironment === ENVIRONMENTS.production &&
      isWhitelistedWorkspace(config.whitelistedWorkspaces, workspaceId)
    ) {
      return true;
    }

    return false;
  } catch (error) {
    // if something goes wrong, just hide the feature!
    return false;
  }
};

export const isYourFilesWorkspacesEnabled = () =>
  isFeatureEnabled(FEATURES.YOUR_FILES_WORKSPACES);

export const isAppsEnabled = () => isFeatureEnabled(FEATURES.APPS);

export const isYourContactsEnabled = () =>
  isFeatureEnabled(FEATURES.YOUR_CONTACTS);

export const isMeetingsEndpointPollingEnabled = () =>
  isFeatureEnabled(FEATURES.MEETINGS_ENDPOINT_POLLING);

export const isWeTeamLaunchBannerEnabled = () =>
  isFeatureEnabled(FEATURES.WE_TEAM_LAUNCH_BANNER);

export const isRegistrationAvailable = () =>
  isFeatureEnabled(FEATURES.REGISTRATION_AVAILABLE);

export const isNotesEnabled = () => isFeatureEnabled(FEATURES.NOTES);
