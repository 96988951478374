import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label, Popup, Segment } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import { noteScope } from 'DataLayer/Notes/scope';

import { showNoteHistoryModal } from 'store/Modals/actions';

import RemoveNoteButton from 'Components/Notes/RemoveNoteButton';
import NoteDate from 'Components/Notes/NoteDate';
import NoteOwnerAvatar from 'Components/Notes/NoteOwnerAvatar';
import WeTeamIcon, { HistoryIcon } from 'Components/Icons/WeTeamIcon';

import styles from './NoteHeader.module.css';

function NoteHeader({ note }) {
  const dispatch = useDispatch();

  const onViewHistoryClick = event => {
    event.stopPropagation();
    dispatch(showNoteHistoryModal(note));
  };

  return (
    <Segment className={styles.header}>
      <NoteDate date={note.createdAt} />
      <span className={styles.headerRight}>
        {note.scope === noteScope.PUBLIC && (
          <Label size="small" color="grey" circular>
            <FormattedMessage id="EditNote.PublicNote" />
          </Label>
        )}
        {note.scope === noteScope.PUBLIC_READ_ONLY && (
          <Label size="small" color="grey" circular>
            <FormattedMessage id="EditNote.PublicReadOnlyNote" />
          </Label>
        )}
        <RemoveNoteButton note={note} />
        <Popup
          inverted
          on="hover"
          size="mini"
          position="top center"
          trigger={
            <WeTeamIcon Icon={HistoryIcon} onClick={onViewHistoryClick} />
          }
        >
          <FormattedMessage id="NoteHeader.History" />
        </Popup>
        <NoteOwnerAvatar note={note} />
      </span>
    </Segment>
  );
}

NoteHeader.propTypes = {
  note: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NoteHeader;
