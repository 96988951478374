import { ENVIRONMENTS } from 'config/config';

export const FEATURES = {
  WE_TEAM_LAUNCH_BANNER: 'WE_TEAM_LAUNCH_BANNER',
  YOUR_CONTACTS: 'YOUR_CONTACTS',
  YOUR_FILES_WORKSPACES: 'YOUR_FILES_WORKSPACES',
  MEETINGS_ENDPOINT_POLLING: 'MEETINGS_ENDPOINT_POLLING',
  REGISTRATION_AVAILABLE: 'REGISTRATION_AVAILABLE',
  CLIENT_CACHING: 'CLIENT_CACHING',
  FILE_CACHING: 'FILE_CACHING',
  NOTES: 'NOTES',
  APPS: 'APPS',
};

export const whiteListedWorkspaces = {};

const commonTeamspaces = [
  '95ea5a73-b0ef-4039-b2f5-d2fa55dfce92',
  '76eba9c7-a1d5-43d3-9355-4b9766859e7f',
  '58e8d786-8666-445f-8985-60cb58a1193a',
  '8275d265-c2f5-49fa-81a1-80dc008243f2',
  '6c0bfa45-615a-4f7b-9484-de78a37bcfe2',
  'e280331d-01fd-4d27-b689-96175715a56e',
];

/*
  Define all feature configuration here. 
  There is no strict schema.
*/
export const featureConfig = {
  [FEATURES.YOUR_CONTACTS]: {
    whitelistedEnvironments: [],
  },
  [FEATURES.APPS]: {
    whitelistedEnvironments: [ENVIRONMENTS.local],
  },
  [FEATURES.NOTES]: {
    whitelistedEnvironments: [
      ENVIRONMENTS.local,
      ENVIRONMENTS.development,
      ENVIRONMENTS.test,
      ENVIRONMENTS.production,
    ],
    whitelistedWorkspaces: commonTeamspaces,
  },
  [FEATURES.CLIENT_CACHING]: {
    whitelistedEnvironments: [
      ENVIRONMENTS.local,
      ENVIRONMENTS.development,
      ENVIRONMENTS.test,
      ENVIRONMENTS.production,
    ],
    whitelistedWorkspaces: commonTeamspaces,
  },
  [FEATURES.FILE_CACHING]: {
    whitelistedEnvironments: [
      ENVIRONMENTS.local,
      ENVIRONMENTS.development,
      ENVIRONMENTS.test,
      ENVIRONMENTS.production,
    ],
    whitelistedWorkspaces: commonTeamspaces,
  },
  [FEATURES.YOUR_FILES_WORKSPACES]: {
    whitelistedEnvironments: [
      ENVIRONMENTS.local,
      ENVIRONMENTS.development,
      ENVIRONMENTS.test,
    ],
  },
  [FEATURES.MEETINGS_ENDPOINT_POLLING]: {
    whitelistedEnvironments: [ENVIRONMENTS.production],
  },
  [FEATURES.WE_TEAM_LAUNCH_BANNER]: {
    whitelistedEnvironments: [
      ENVIRONMENTS.local,
      ENVIRONMENTS.test,
      ENVIRONMENTS.development,
    ],
  },
  [FEATURES.REGISTRATION_AVAILABLE]: {
    whitelistedEnvironments: [
      ENVIRONMENTS.local,
      ENVIRONMENTS.test,
      ENVIRONMENTS.development,
      ENVIRONMENTS.production,
    ],
  },
};

export const isWhitelistedEnvironment = (
  environments = [],
  currentEnvironment
) => environments?.includes(currentEnvironment);

export const isWhitelistedWorkspace = (workspaces = [], workspaceId) =>
  workspaces?.includes(workspaceId);
