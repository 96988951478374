import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

import getInitials from 'Helpers/Strings/getInitials';

import collaboratorPresence from 'Constants/collaboratorPresence';

import './Avatar.scss';
import ImageLoader from '../Avatar/ImageLoader';

const MIN_FONT_SIZE = 9;
const FONT_SIZE_DIVISOR = 2.5;

export const getFontSize = width => {
  const fontSize = Math.abs(width / FONT_SIZE_DIVISOR);
  const fontSizeLimited = fontSize < MIN_FONT_SIZE ? MIN_FONT_SIZE : fontSize;
  // ensure an odd number to help with vertical alignment
  return fontSizeLimited % 2 === 0 ? fontSizeLimited + 1 : fontSizeLimited;
};

class Avatar extends React.PureComponent {
  render() {
    const {
      firstName,
      lastName,
      email,
      presence,
      avatarUrl,
      color,
      width,
      height,
      showStatus,
      title,
      disabled,
      fallbackName,
      canClick,
      ...rest
    } = this.props;

    const initials = getInitials(firstName, lastName, email, fallbackName);

    const avatarClass = classNames('collaborator-avatar', {
      disabled: disabled,
      online: showStatus && presence === collaboratorPresence.ONLINE,
      offline: showStatus && presence === collaboratorPresence.OFFLINE,
      away: showStatus && presence === collaboratorPresence.AWAY,
      canClick,
    });

    return (
      <span className={avatarClass} title={title} {...rest}>
        <ImageLoader src={avatarUrl}>
          {(loading, error) => {
            const srcAvailable = avatarUrl && !loading && !error;
            return (
              <span
                style={{
                  height,
                  width,
                  minWidth: width,
                  fontSize: getFontSize(width),
                  backgroundColor: color,
                  backgroundImage: srcAvailable ? `url(${avatarUrl})` : null,
                  /* 
                    If there is a background image, we want to hide
                    the initials. This method is better than not rendering the initials
                    because we don't have to deal with layout issues.
                    #00000000 is transparent
                  */
                  color: srcAvailable ? '#00000000' : null,
                  backgroundSize: `${width}px ${height}px`,
                }}
              >
                {initials}
              </span>
            );
          }}
        </ImageLoader>
      </span>
    );
  }
}

Avatar.propTypes = {
  avatarUrl: PropTypes.string,
  color: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  presence: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  showStatus: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  fallbackName: PropTypes.string,
};

Avatar.defaultProps = {
  width: 40,
  height: 40,
  showStatus: true,
  fallbackName: '',
  canClick: true,
};

export default Avatar;
